import React from 'react';
import styles from './BroadcastReference.module.scss';
import BroadcastReferenceItem from "./BroadcastReferenceItem";

const BroadcastReference = ({data}) => {
    const {tracks} = data

    return (
        <div className={styles.container}>
            {tracks.map((track, index) => {
                let isFirstOfColor = false;
                let isLastOfColor = false;

                if (index === 0 || tracks[index - 1].color !== track.color) {
                    isFirstOfColor = true;
                }

                if (index === tracks.length - 1 || tracks[index + 1].color !== track.color) {
                    isLastOfColor = true;
                }

                return <BroadcastReferenceItem data={track} key={index} isLastOfColor={isLastOfColor}
                                               isFirstOfColor={isFirstOfColor}/>;
            })}
        </div>
    );
};

export default BroadcastReference;
