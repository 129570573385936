import React, { useState } from 'react';
import styles from './BroadcastManagementScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { Link } from "react-router-dom";
import classNames from 'class-names';
import BroadcastHoursScene from "../BroadcastHoursScene";
import { ObjectSceneVolume } from "../../../../components/ObjectScene/ObjectSceneVolume";

const breadcrumbsRoutes = [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Объекты',
    href: '/radio/objects'
}, {
    name: 'Страница объекта',
    href: '/radio/object'
}, {
    name: 'Управление эфиром',
    href: '/radio/object/broadcast-management'
}];

const BroadcastManagementScene = ({objectId}) =>
    (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes} className={styles.breadcrumbs}/>
            <div className={styles.broadcastManagement}>
                <div className={styles.objectTitle}>
                    09385-19 ТГПУ Очное заочное
                </div>
                <div className={styles.title}>
                    Управление эфиром
                </div>
                <ObjectSceneVolume objectId={objectId}/>
            </div>
            <div className={styles.buttonsContainer}>
                <Link to={'/radio/object/broadcast-hours'} className={classNames(styles.button, styles.hideDesktop)}>
                    <div className={styles.timeIcon}/>
                </Link>
                <Link to={`/radio/objects/${objectId}/pause`} className={styles.button}>
                    <div className={styles.pauseIcon}/>
                    <div className={styles.buttonText}>
                        Приостановка эфира
                    </div>
                </Link>
                <Link to={`/radio/objects/${objectId}/reference`} className={styles.button}>
                    <div className={styles.calendarIcon}/>
                    <div className={styles.buttonText}>
                        Эфирная справка
                    </div>
                </Link>
                <div className={styles.hideMobile}>
                    <BroadcastHoursScene/>
                </div>
            </div>
        </div>
    );

export default BroadcastManagementScene;
