import React, {useState} from 'react';
import styles from './ObjectScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import classNames from 'class-names';
import ObjectScenePlayer from "../../../../components/ObjectScene/ObjectScenePlayer";
import Tabs from "../../../../components/Tabs";
import Tab from "../../../../components/Tabs/Tab";
import ObjectSceneInfo from "../../../../components/ObjectScene/ObjectSceneInfo";
import BroadcastManagementScene from "../BroadcastManagementScene";
import Button from "../../../../components/common/Button";
import {Link} from "react-router-dom";
import disableSoundIcon from '../../../../img/icons/disable-sound-icon.svg';
import enableSoundIcon from '../../../../img/icons/enable-sound-icon.svg';
import {radioDataSelector, radioParamsSelector} from "../../../../redux/modules/radio/radio-selectors";
import {handleRadioError, loadRadioData, setRadioParams} from "../../../../redux/modules/radio/radio-actions";
import {RADIO_ENTITY} from "../../../../constants/radioEntities";
import {connect, useDispatch, useSelector} from "react-redux";
import {useRadioMetadata} from "../../../../hooks/radio/useRadioMetadata";
import Spinner from "../../../../components/Spinner";
import {getOptionalValue} from "../../../../utils/getOptionalValue";
import {isRadioPageLoaded} from "../../../../redux/modules/radio/radio-helpers";
import {useRequest} from "ahooks";
import {fetchPriorityAds, playPriorityAd, unmuteObject} from "../../../../api/radio-api";
import {
    loadObjectVolumeLevels,
    setObjectVolumeLevel,
    volumeLevelsSelector
} from "../../../../redux/modules/radio/slices/volume-levels-slice";
import {useDebouncedCallback} from "../../../../hooks/useDebouncedCallback";

const breadcrumbsRoutes = (objectId, title) => (
    [{
        name: 'Корпоративное радио',
        href: '#'
    }, {
        name: 'Объекты',
        href: '/radio/objects'
    }, {
        name: title || 'Страница объекта',
        href: `/radio/objects/${objectId}`
    }
]);

const mapStateToProps = (state) => ({
    entityData: radioDataSelector(state),
    params: radioParamsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadObjectData: (id) => dispatch(loadRadioData(RADIO_ENTITY.OBJECT, id)),
    setParams: (params) => dispatch(setRadioParams(params)),
})

const ObjectScene = ({
                         match,
                         entityData,
                         params,
                         loadObjectData,
                         setParams,
                     }) => {
    const dispatch = useDispatch();
    const {id} = match.params;
    const volumeLevels = useSelector(volumeLevelsSelector);
    useRadioMetadata();

    console.log({ id})

    React.useEffect(() => {
        loadObjectData(id)
    }, [id, loadObjectData]);

    const muted = volumeLevels.master === 0;
    const [isRecording, setIsRecording] = useState(false);
    const [favoriteAnnouncement, setFavoriteAnnouncement] = useState(null);

    const onChangeVolume = useDebouncedCallback((volume) => {
        dispatch(setObjectVolumeLevel({objectId: id, type: 'master', volume}))
    }, 500);

    useRequest(fetchPriorityAds, {
        defaultParams: [id],
        onSuccess: ({data}) => {
            const newFavoriteAnnouncement = data.find(announcement => announcement.favorite);
            setFavoriteAnnouncement(newFavoriteAnnouncement);
        }
    });

    const onFavoritePlay = () => {
        playPriorityAd(id, favoriteAnnouncement.id)
    }

    const isLoaded = isRadioPageLoaded(RADIO_ENTITY.OBJECT, entityData);

    const objectInfo = entityData && entityData.data;

    const onToggleMute = async () => {
        if (volumeLevels.master === 0) {
            try {
                await unmuteObject(id);
                await dispatch(loadObjectVolumeLevels({objectId: id}));
            } catch (e) {
                dispatch(handleRadioError(e));
            }
        } else {
            onChangeVolume(0);
        }
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes(id, objectInfo?.title)}/>
            {isLoaded ? (
                <>
                    <div className={styles.playerContainer}>
                        <div className={classNames(styles.flexContainer, styles.headContent)}>
                            <div className={styles.title}>
                                {getOptionalValue(objectInfo, 'title')}
                                <div className={styles.address}>
                                    {getOptionalValue(objectInfo, 'address')}
                                </div>
                            </div>
                            <div className={classNames(styles.muteContainer, {[styles.muted]: muted})}
                                 onClick={onToggleMute}>
                                <div className={styles.soundButtonContainer}>
                                    <img alt={''} className={styles.soundButton}
                                         src={!muted ? disableSoundIcon : enableSoundIcon}/>
                                </div>
                                <div className={styles.muteText}>{muted ? 'Включить' : 'Отключить'} звук</div>
                            </div>
                            <Link to={`/radio/objects/${id}/priority`} className={styles.priorityButton}>
                                <div className={styles.priorityIcon}/>
                                Приоритетные объявления
                            </Link>
                            <Link to={`/radio/object/background/${id}`} className={styles.backgroundButton}>
                                <div className={styles.backgroundIcon}>
                                    Фон
                                </div>
                            </Link>
                        </div>
                        <div className={classNames(styles.flexContainer, styles.info)}>
                            <div className={styles.flexCenter}>
                                <div className={styles.objectIcon}/>
                                ID {getOptionalValue(objectInfo, 'id')}
                            </div>
                            <div className={classNames(styles.statusContainer)}>
                                {getOptionalValue(objectInfo, 'status.name')}
                                {objectInfo.online && (<>
                                        <div className={styles.onlineStatus}/>
                                        Online
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={styles.pageContent}>
                            <ObjectScenePlayer id={id}
                                               isMuted={muted}
                                               setRecording={setIsRecording}
                                               isRecording={isRecording}/>
                            <div
                                className={classNames(styles.favoriteAdContainer, styles.hideMobile, styles.hideTablet)}>
                                {favoriteAnnouncement && <>
                                    <div className={styles.favoriteAdTitle}>
                                        Избранное объявление
                                    </div>
                                    <div className={styles.favoriteAd} onClick={onFavoritePlay}>
                                        <div className={styles.favoriteAdIcon}/>
                                        <div className={styles.favoriteAdName}>
                                            {favoriteAnnouncement.title}
                                        </div>
                                    </div>
                                </>}
                                <div className={styles.microphoneIconContainer}
                                     onClick={() => setIsRecording(!isRecording)}>
                                    <div className={classNames(styles.microphoneIcon, {[styles.active]: isRecording})}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.tabsContainer}>
                        <Tabs>
                            <Tab title={'Управление эфиром'} hideOnMobile>
                                <BroadcastManagementScene objectId={id}/>
                            </Tab>
                            <Tab title={'Избранное'} hideOnDesktop>
                                <div className={styles.favoriteAdContainer}>
                                    <div className={styles.favoriteAd}>
                                        <div className={styles.favoriteAdIcon}/>
                                    </div>
                                    <div
                                        className={classNames(styles.microphoneIconContainer, {[styles.active]: isRecording})}
                                        onClick={() => setIsRecording(!isRecording)}>
                                        <div
                                            className={classNames(styles.microphoneIcon, {[styles.active]: isRecording})}/>
                                    </div>
                                </div>
                            </Tab>
                            <Tab title={'Информация об объекте'}>
                                <Button className={styles.maintenanceButton}
                                        href={`/radio/object/${id}/maintenance`}>
                                    Техобслуживание
                                </Button>
                                <ObjectSceneInfo objectInfo={objectInfo}/>
                            </Tab>
                        </Tabs>
                    </div>
                </>
            ) : (
                <div className={styles.spinnerContainer}>
                    <Spinner/>
                </div>
            )}

        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectScene);
