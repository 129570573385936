import React, {useEffect, useState, useCallback} from 'react';
import styles from './ObjectMaintenanceScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import Button from "../../../../components/common/Button";
import Input from "../../../../components/common/Input";
import MaintenanceCard from "../../../../components/Cards/MaintenanceCard";
import Pagination from "../../../../components/Pagination";
import { useSetCurrentSection } from "../../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../../constants/pageTypes";
import ListContainer from "../../../../components/ListContainer";
import {useDispatch, useSelector} from "react-redux";
import {profileSelectedObject, profileSelectorMaintenances} from "../../../../redux/modules/profile/profile-selectors";
import {loadMaintenances, setSelectedObject} from "../../../../redux/modules/profile/profile-actions";
import {useHistory, useLocation, useParams} from "react-router-dom";
import Spinner from "../../../../components/Spinner";

const ObjectMaintenanceScene = ({ archive }) => {
    const location = useLocation();
    const { id: objectId } = useParams();
    const isTechSupport = location.state?.techSupport;
    useSetCurrentSection(isTechSupport ? PAGE_SUBSECTIONS.TECH_SUPPORT : PAGE_SUBSECTIONS.OBJECTS);
    const dispatch = useDispatch();
    const history = useHistory();
    const maintenances = useSelector(profileSelectorMaintenances);
    const selectedObject = useSelector(profileSelectedObject);
    const [isLoading, setIsLoading] = useState(true);

    const breadcrumbsRoutes = !isTechSupport ? [
        {
            name: 'Корпоративное радио',
            href: '#'
        }, {
            name: 'Объекты',
            href: '/radio/objects'
        }, {
            name: 'Страница объекта',
            href: `/radio/objects/${selectedObject?.[0]?.id}`
        }, {
            name: selectedObject?.[0]?.title ?? 'Техобслуживание',
            href: '/radio/object/maintenance'
        }
    ] : [
            {
                name: 'Тех. сопровождение',
                href: '/profile/tech-support'
            }, {
                name: selectedObject?.[0]?.title ?? 'Техобслуживание',
                href: '/radio/object/maintenance'
            }
    ];

    useEffect(() => {
        const params = {
            objectId: objectId || selectedObject[0]?.id,
            _sort: '-id',
            _page: 0,
            _pageSize: 10
        }
        !archive ? (
            dispatch(loadMaintenances(params))
        ) : (
            dispatch(loadMaintenances({
                status: 'CLOSED',
                ...params
            }))
        )
        setIsLoading(false);
    }, [dispatch, archive, selectedObject]);

    const archiveRoutes = [...breadcrumbsRoutes, { name: 'Архив ТО', href: objectId ? `/radio/object/${objectId}/maintenance/archive` : '/radio/object/maintenance/archive'}];

    const setPage = (page) => {
        dispatch(loadMaintenances( {
            _page: page,
            _pageSize: 10,
            objectId: objectId || selectedObject[0]?.id,
            _sort: '-id'
        }));
    }

    const onChangeDate = async (value) => {
       await setIsLoading(true);
        const params = archive ? {
            status: 'CLOSED',
            dateCreatedFrom: value,
            dateCreatedTo: value,
            objectId: objectId || selectedObject[0]?.id,
            _sort: '-id',
            _page: 0,
            _pageSize: 10
        } : {
            dateCreatedFrom: value,
            dateCreatedTo: value,
            objectId: objectId || selectedObject[0]?.id,
            _sort: '-id',
            _page: 0,
            _pageSize: 10
        };

        await dispatch(loadMaintenances(params));
        await setIsLoading(false);
    };

    const addLocation = {
        pathname: `/radio/object/${objectId || selectedObject[0]?.id}/maintenance/add`,
        state: { isTechSupport }
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={archive ? archiveRoutes : breadcrumbsRoutes}/>
            <div className={styles.pageSettings}>
                {!archive &&
                    <div className={styles.buttonsContainer}>
                        <Button className={styles.addButton}
                                onClick={() => history.push(addLocation)}>
                            Создать заявку
                        </Button>
                        <Button href={objectId ? `/radio/object/${objectId}/maintenance/archive` : '/radio/object/maintenance/archive'}
                                className={styles.archiveButton}>
                            Архив
                        </Button>
                    </div>
                }
                <div className={styles.dateInputContainer}>
                    <Input
                       name='date'
                       type='date'
                       className={styles.dateInput}
                       onChange={(e) => onChangeDate(e.target.value)}
                       label={'Дата заявки'} />
                </div>
            </div>
            {isTechSupport && (
                <Button className={styles.buttonBack}
                        onClick={() => {
                            history.goBack();
                            dispatch(setSelectedObject([]));
                        }}>
                    Назад
                </Button>
            )}
            {isLoading ? (
                <div className={styles.spinnerContainer}>
                    <Spinner/>
                </div>
            ) : (
                <ListContainer className={styles.listContainer} emptyText="Заявки отсутствуют">
                    {maintenances?.items?.map(item =>
                        <MaintenanceCard item={item} key={item.id} />
                    )}
                </ListContainer>
            )}
            {!!maintenances?.items?.length &&
                <Pagination
                    count={maintenances?.totalElements}
                    entityName="Заявок"
                    totalPages={maintenances?.totalPages}
                    resultsPerPage={maintenances?.pageSize}
                    currentPage={maintenances?.pageNumber}
                    setPage={setPage}
                    items={maintenances?.items}/>
            }
        </div>
    );
};

export default ObjectMaintenanceScene;
