import React, {useState, useEffect, useRef, useCallback} from 'react';
import styles from './ObjectScenePlayer.module.scss';
import classNames from 'class-names';
import { Link } from "react-router-dom";
import { useRequest } from "ahooks";
import { getTrackOnAir, uploadMicrophoneAudio } from "../../../api/radio-api";
import {useDebouncedCallback} from "../../../hooks/useDebouncedCallback";
import {setObjectVolumeLevel, volumeLevelsSelector} from "../../../redux/modules/radio/slices/volume-levels-slice";
import {useDispatch, useSelector} from "react-redux";
import {RADIO_URL} from "../../../api/config";

const ObjectScenePlayer = ({ id, isMuted, isRecording, setRecording }) => {
    const dispatch = useDispatch();
    const volumeLevels = useSelector(volumeLevelsSelector);
    const [volume, setVolume] = useState();
    const [isPaused, setPaused] = useState(true);
    const [message, setMessage] = useState('');
    const playerRef = useRef();

    const link = `${RADIO_URL}/objects/${id}/listen-to-air`;

    const onChange = useDebouncedCallback((volume) => {
        dispatch(setObjectVolumeLevel({objectId: id, type: 'master', volume: Number(volume)}))
    }, 500);

    useEffect(() => {
        if (!volumeLevels.isLoading) {
            setVolume(volumeLevels.master);
        }
    }, [volumeLevels]);

    useEffect(() => {
        if (volume !== undefined && volume !== volumeLevels.master) {
            onChange(volume);
        }
    }, [volume])

    useEffect(() => {
        isRecording && setPaused(true);
    }, [isRecording, setPaused]);

    useEffect(() => {
        !isPaused && setRecording(false);
    }, [isPaused, setRecording]);

    useEffect(() => {
        if (isPaused) {
            playerRef.current.pause();
        } else {
            playerRef.current.play();
            playerRef.current.volume = 1;
        }
    }, [isPaused]);

    useEffect(() => {
        if (isRecording) {
            let currentStream;
            let mediaRecorder;

            if (!navigator || !navigator.mediaDevices) {
                return;
            }

            navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                currentStream = stream;
                mediaRecorder = new MediaRecorder(stream);
                mediaRecorder.start();

                const audioChunks = [];
                mediaRecorder.addEventListener("dataavailable", event => {
                    audioChunks.push(event.data);
                });

                mediaRecorder.addEventListener("stop", () => {
                    const audioBlob = new Blob(audioChunks, { type: 'audio/mpeg' });
                    uploadMicrophoneAudio(id, audioBlob).catch(e => console.error(e));
                });
            });

            return () => {
                mediaRecorder.stop();
                currentStream.getTracks().forEach(track => track.stop())
            }
        }
    }, [id, isRecording])

    useEffect(() => {
        playerRef.current.muted = isMuted;
    }, [isMuted])

    useRequest(getTrackOnAir, {
        defaultParams: [id],
        onSuccess: (data) => {
            setMessage(data.data.title);
        },
        onError: (error => {
            setPaused(true);
            setMessage(error.response.data.errorMessage);
        })
    });


    const visualiserAnimated = !isPaused && !isMuted && volumeLevels.master > 0 && !isRecording;
    const visualiserMuted = isMuted || isRecording;

    return (
        <div className={styles.container}>
            <div
                className={classNames(styles.visualiser,
                    {
                        [styles.visualiserAnimated]: visualiserAnimated,
                        [styles.visualiserMuted]: visualiserMuted
                    })}>
                <div className={styles.innerCircle} style={{ height: `${volumeLevels.master}%`, width: `${volumeLevels.master}%` }}>
                    <div className={styles.innerCircle}>
                        {isRecording ? <div className={styles.microphoneIcon}/> : <div className={classNames(styles.innerCircle, styles.centerCircle)}/>}
                    </div>
                </div>
                {!isRecording &&
                <div className={styles.volumeContainer}>
                    {volume}
                </div>}
                <audio className={styles.audioPlayer} type="audio/mpeg" ref={playerRef} src={link}/>
            </div>
            <div className={styles.titleContainer}>
                <Link to={'/radio/object/broadcast-management'} className={styles.settingsIcon}/>
                <Link to={`/radio/object/background/${id}`} className={styles.backgroundIcon}/>
                <Link to={`/radio/objects/${id}/priority`} className={styles.lightningIcon}/>
                <div className={classNames(styles.titleText, { [styles.scrollingTitle]: !isPaused })}>
                    <span>{message}</span>
                </div>
            </div>
            <div className={styles.playerControls}>
                <div className={styles.minusButton}
                     onClick={() => !isMuted && setVolume(volume > 0 ? volume - 1 : volume)}>
                    -
                </div>
                <div className={classNames(styles.playButton, { [styles.paused]: isPaused })}
                     onClick={() => setPaused(!isPaused)}
                />
                <div className={styles.plusButton}
                     onClick={() => !isMuted && setVolume(volume < 100 ? volume + 1 : volume)}>
                    +
                </div>
            </div>
        </div>
    );
};

export default ObjectScenePlayer;
