import React, {useEffect, useState} from 'react';
import styles from './MediaplanObjectsScene.module.scss';
import { useSetCurrentSection } from "../../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../../constants/pageTypes";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import mediaplanIcon from "../../../../img/icons/mediaplan-icon-grey.svg";
import ItemListTitle from "../../../../components/ItemListTitle";
import MediaplanObjectCard from "../../../../components/Cards/MediaplanObjectCard";
import CardCheckbox from "../../../../components/Cards/CardCheckbox";
import ListContainer from "../../../../components/ListContainer";
import { useRadioMediaplan } from "../../../../hooks/radio/useRadioMediaplan";
import { editRadioMediaplan } from "../../../../redux/modules/radio/radio-actions";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { api } from "../../../../App";
import {RADIO_URL} from "../../../../api/config";

const generateBreadcrumbsRoutes = (id) => [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Эфир',
    href: '/radio/broadcast'
}, {
    name: 'Страница медиаплана',
    href: `/radio/mediaplans/${id}`
}, {
    name: 'Объекты медиаплана',
    href: `/radio/mediaplans/${id}/objects`
}];

const MediaplanObjectsScene = ({ match }) => {
    useSetCurrentSection(PAGE_SUBSECTIONS.BROADCAST);
    const { id } = match.params;
    const history = useHistory();
    const dispatch = useDispatch();
    const [entityData] = useRadioMediaplan(id);
    const [selectedObjects, setSelectedObjects] = useState([]);
    const [objectsList, setObjectsList] = useState([]);

    useEffect(() => {
        api.get(`${RADIO_URL}/objects`)
            .then((res) =>
                setObjectsList(res.data.items))
    },[])

    const mediaplanInfo = entityData?.data;

    useEffect(() => {
        setSelectedObjects(mediaplanInfo?.objects ?? [])
    }, [mediaplanInfo]);

    const onToggle = (object) => {
        return selectedObjects.find(({ id }) => id === object.id) ?
            setSelectedObjects(selectedObjects.filter(item => item.id !== object.id)) :
            setSelectedObjects([...selectedObjects, object]);
    }

    const onDelete = (id) => {
        const filteredList = selectedObjects?.filter(item => item.id !== id)?.map(object => object.id);
        dispatch(editRadioMediaplan({ ...mediaplanInfo, objects: filteredList  }, mediaplanInfo?.id));
    }

    const onSubmit = async () => {
        await dispatch(editRadioMediaplan({
            objects: selectedObjects?.map(object => object.id)
        }, mediaplanInfo?.id))
        history.push(`/radio/mediaplans/${id}`);
    }

    return (
        <>
            <div className={styles.container}>
                <Breadcrumbs routes={generateBreadcrumbsRoutes(id)}/>
                <ItemListTitle icon={mediaplanIcon}
                               title={mediaplanInfo?.title}>
                    ID {mediaplanInfo?.id}
                </ItemListTitle>
                <ListContainer className={styles.listContainer} noLoader>
                    {objectsList?.map((item) =>
                        <MediaplanObjectCard
                            key={item.id}
                            id={item.id}
                            name={item.title}
                            address={item.address}
                            onClick={(e) => {
                                onToggle(item);
                                e.preventDefault();
                            }}
                            showDeleteButton={!!mediaplanInfo?.objects.find(({ id }) => id === item.id)}
                            onDelete={onDelete}
                            checkbox={
                                <CardCheckbox
                                    onChange={() => onToggle(item)}
                                    checked={!!selectedObjects.find(({ id }) => id === item.id)}/>
                            }
                        />
                    )}
                </ListContainer>
            </div>
            <div className={styles.endBlock}>
                <div className={styles.buttons}>
                    <Link to={`/radio/mediaplans/${id}`}>
                        <button className={styles.back}>Назад</button>
                    </Link>
                    <button className={styles.ready} onClick={onSubmit}>
                        Готово
                    </button>
                </div>
            </div>
        </>
    );
};

export default MediaplanObjectsScene;
