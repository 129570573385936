import React from 'react';
import LoginScreen from "./scenes/LoginScene";
import AuthScene from './scenes/AuthScene';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { configureStore } from "./redux/store";
import { initialState } from "./redux/reducers/rootReducer";
import { Provider } from "react-redux";
import PrivateRoute from "./components/Routing/PrivateRoute"
import RecordsListScene from "./scenes/RecordsSection/RecordsListScene";
import RegisterScene from "./scenes/RegisterScene";
import Layout from "./scenes/Layout";
import RecordScene from "./scenes/RecordScene";
import ArchiveListScene from "./scenes/RecordsSection/ArchiveListScene";
import DemoListScene from "./scenes/RecordsSection/DemoListScene";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import ObjectsListScene from "./scenes/RadioSection/Objects/ObjectsListScene";
import BroadcastListScene from "./scenes/RadioSection/Mediaplan/BroadcastListScene";
import PriorityListScene from "./scenes/RadioSection/Priority/PriorityListScene";
import ObjectScene from "./scenes/RadioSection/Objects/ObjectScene";
import ObjectCreateScene from "./scenes/RadioSection/Objects/ObjectCreateScene";
import MediaplanScene from "./scenes/RadioSection/Mediaplan/MediaplanScene";
import TimelineScene from "./scenes/RadioSection/Mediaplan/TimelineScene";
import MediaplanCreateScene from "./scenes/RadioSection/Mediaplan/MediaplanCreateScene";
import MediaplanEditScene from "./scenes/RadioSection/Mediaplan/MediaplanEditScene";
import MediaplanAddObjectsScene from "./scenes/RadioSection/Mediaplan/MediaplanAddObjectsScene";
import MediaplanRecordsScene from "./scenes/RadioSection/Mediaplan/MediaplanRecordsScene";
import MediaplanRecordTimeScene from "./scenes/RadioSection/Mediaplan/MediaplanRecordTimeScene";
import MediaplanAddRecordScene from "./scenes/RadioSection/Mediaplan/MediaplanAddRecordScene";
import MediaplanObjectsScene from "./scenes/RadioSection/Mediaplan/MediaplanObjectsScene";
import PriorityCreateScene from "./scenes/RadioSection/Priority/PriorityCreateScene";
import PriorityRecordsScene from "./scenes/RadioSection/Priority/PriorityRecordsScene";
import BroadcastManagementScene from "./scenes/RadioSection/Objects/BroadcastManagementScene";
import BackgroundScene from "./scenes/RadioSection/Objects/BackgroundScene";
import ListPlaylists from "./scenes/RadioSection/Objects/BackgroundScene/ListPlaylists";
import CalendarPlaylist from "./scenes/RadioSection/Objects/BackgroundScene/CalendarPlaylist";
import BroadcastReferenceScene from "./scenes/RadioSection/Objects/BroadcastReferenceScene";
import BroadcastPauseScene from "./scenes/RadioSection/Objects/BroadcastPauseScene";
import ObjectMaintenanceScene from "./scenes/RadioSection/Objects/ObjectMaintenanceScene";
import ObjectMaintenanceAddScene from "./scenes/RadioSection/Objects/ObjectMaintenanceAddScene";
import ObjectPriorityAdScene from "./scenes/RadioSection/Objects/ObjectPriorityAdsScene";
import BroadcastHoursScene from "./scenes/RadioSection/Objects/BroadcastHoursScene";
import { DEFAULT_ROUTE } from "./constants/routing";
import RestorePasswordScene from "./scenes/RestorePasswordScene";
import ProfileScene from "./scenes/ProfileSection/ProfileScene";
import BalanceHistory from "./scenes/ProfileSection/BalanceHistory";
import TechSupportScene from "./scenes/ProfileSection/TechSupport";
import IdeasScene from "./scenes/ProfileSection/IdeasScene";
import FeedbackScene from "./scenes/ProfileSection/FeedbackScene";
import DocumentsScene from "./scenes/ProfileSection/DocumentsScene";
import EditUserDataScene from "./scenes/ProfileSection/EditUserDataScene";
import BackgroundPlaylists from "./scenes/Background/BackgroundPlaylists"
import BackgroundRAO from "./scenes/Background/BackgroundRAO"
import PlaylistInfo from "./scenes/Background/PlaylistInfo"
import PlaylistEdit from "./scenes/Background/PlaylistEdit"
import PlaylistOrder from "./scenes/Background/PlaylistOrder"
import { configureApi } from "./api";
import {OrdersListScene} from "./scenes/RecordsSection/OrdersListScene";

const store = configureStore(null, initialState);
export const api = configureApi(store);

function App() {
    return (
        <Provider store={store}>
            <Router>
                <Switch>
                    <Route exact path={'/auth'} component={AuthScene}/>
                    <Route exact path={'/login'} component={LoginScreen}/>
                    <Route exact path={'/register'} component={RegisterScene}/>
                    <Route exact path={'/restore-password'} component={RestorePasswordScene}/>
                    <Route path={'/'} render={() => (
                        <Layout>
                            <PrivateRoute exact path={'/studio/records'} component={RecordsListScene}/>
                            <PrivateRoute exact path={'/studio/archive'} component={ArchiveListScene}/>
                            <PrivateRoute exact path={'/studio/demo'} component={DemoListScene}/>
                            <PrivateRoute exact path={'/studio/orders'} component={OrdersListScene}/>
                            <PrivateRoute exact path={'/studio/:entity/:id'} component={RecordScene}/>

                            <PrivateRoute exact path={'/radio/objects'} component={ObjectsListScene}/>
                            <PrivateRoute exact path={'/radio/objects/:id'} component={ObjectScene}/>
                            <PrivateRoute exact path={'/radio/object/create'} component={ObjectCreateScene}/>
                            <PrivateRoute exact path={'/radio/object/broadcast-management'}
                                          component={BroadcastManagementScene}/>
                            <PrivateRoute exact path={'/radio/object/background/:objectId'} component={BackgroundScene}/>
                            <PrivateRoute exact path={'/radio/object/background/:objectId/playlists'} component={ListPlaylists}/>
                            <PrivateRoute exact path={'/radio/object/background/:objectId/playlists/calendar/:playlistId'}
                                          component={CalendarPlaylist}/>
                            <PrivateRoute exact path={'/radio/objects/:id/reference'} component={BroadcastReferenceScene}/>
                            <PrivateRoute exact path={'/radio/objects/:id/pause'} component={BroadcastPauseScene}/>
                            <PrivateRoute exact path={'/radio/object/broadcast-hours'} component={BroadcastHoursScene}/>

                            <PrivateRoute exact path={'/radio/objects/:id/priority'} component={ObjectPriorityAdScene}/>
                            <PrivateRoute exact path={'/radio/objects/:id/priority/create'} component={PriorityCreateScene}/>

                            <PrivateRoute exact path={'/radio/object/maintenance'} component={ObjectMaintenanceScene}/>
                            <PrivateRoute exact path={'/radio/object/:id/maintenance'} component={ObjectMaintenanceScene}/>
                            <PrivateRoute exact path={'/radio/object/:id/maintenance/archive'}
                                          component={() => <ObjectMaintenanceScene archive/>}/>
                            <PrivateRoute exact path={'/radio/object/:id/maintenance/add'}
                                          component={ObjectMaintenanceAddScene}/>
                            <PrivateRoute exact path={'/radio/object/maintenance/archive'}
                                          component={() => <ObjectMaintenanceScene archive/>}/>
                            <PrivateRoute exact path={'/radio/broadcast'} component={BroadcastListScene}/>
                            <PrivateRoute exact path={'/radio/mediaplans/:id'} component={MediaplanScene}/>
                            <PrivateRoute exact path={'/radio/mediaplans/:id/edit'} component={MediaplanEditScene}/>
                            <PrivateRoute exact path={'/radio/mediaplans/:id/objects'} component={MediaplanObjectsScene}/>
                            <PrivateRoute exact path={'/radio/mediaplan/create'} component={MediaplanCreateScene}/>
                            <PrivateRoute exact path={'/radio/mediaplan/objects/add'}
                                          component={MediaplanAddObjectsScene}/>
                            <PrivateRoute exact path={'/radio/mediaplans/:id/records'} component={MediaplanRecordsScene}/>
                            <PrivateRoute exact path={'/radio/mediaplan/records/add'}
                                          component={MediaplanAddRecordScene}/>
                            <PrivateRoute exact path={'/radio/mediaplan/record/time'}
                                          component={MediaplanRecordTimeScene}/>
                            <PrivateRoute exact path={'/radio/mediaplan/timeline'} component={TimelineScene}/>
                            <PrivateRoute exact path={'/radio/priority'} component={PriorityListScene}/>
                            <PrivateRoute exact path={'/radio/priority/create'} component={PriorityCreateScene}/>
                            <PrivateRoute exact path={'/radio/priority/create/records'}
                                          component={PriorityRecordsScene}/>
                            <PrivateRoute exact path={'/profile'} component={ProfileScene}/>
                            <PrivateRoute exact path={'/profile/history'} component={BalanceHistory}/>
                            <PrivateRoute exact path={'/profile/tech-support'} component={TechSupportScene}/>
                            <PrivateRoute exact path={'/profile/ideas'} component={IdeasScene}/>
                            <PrivateRoute exact path={'/profile/feedback'} component={FeedbackScene}/>
                            <PrivateRoute exact path={'/profile/documents'} component={DocumentsScene}/>
                            <PrivateRoute exact path={'/profile/edit-data'} component={EditUserDataScene}/>
                            <PrivateRoute exact path={'/background/playlists'} component={BackgroundPlaylists}/>
                            <PrivateRoute exact path={'/background/RAO'} component={BackgroundRAO}/>
                            <PrivateRoute exact path={'/background/playlist/:id'} component={PlaylistInfo}/>
                            <PrivateRoute exact path={'/background/playlist/:id/edit'} component={PlaylistEdit}/>
                            <PrivateRoute exact path={'/background/playlist/:id/order'} component={PlaylistOrder}/>
                            <Route exact path={'/'} render={() => <Redirect to={DEFAULT_ROUTE}/>}/>
                        </Layout>
                    )}/>
                </Switch>
            </Router>
        </Provider>
    );
}

export default App;
