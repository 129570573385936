import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GenericCard from "../GenericCard";
import styles from './RecordCard.module.scss';
import RecordStatus from "./RecordStatus";
import Popup from "../../Popup";
import classNames from "class-names";
import recordIcon from "../../../img/icons/record-icon.svg";
import AudioPlayer from "../../AudioPlayer";

const RecordCard = ({ record, onDelete, checkbox, icon = recordIcon, showStatus, showMenu, onClick  }) => {
    const [popupShown, setPopupShown] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [menuOptions, setMenuOptions] = useState(null);

    const category = record.categories ? record.categories[0] : null;

    const getRecordMenuOptions = (record) => (<>
        <div onClick={() => onDelete(record.id)} className="popup-menu-item">
            Удалить
        </div>
    </>);

    React.useEffect(() => {
        setMenuOptions(getRecordMenuOptions(record));
    }, [audioUrl])

    return (
        <GenericCard className={styles.card}
                     onClick={onClick}
                     headClassName={classNames(styles.head, { [styles.headOnline]: record.onAir })}
                     headContent={<>
                         <div className={styles.headInfo}>
                             <div className={styles.checkboxLeftContainer}>{checkbox}</div>
                             <div className={styles.icon} style={{ backgroundImage: `url(${icon})` }}/>
                             ID {record.id}
                             {showStatus && ` • ${record.status.name}`}
                             {showStatus && <RecordStatus onlineStatus={record.onAir} status={record.status.name}/>}
                             {showMenu && (
                                 <div className={styles.menuButton} onClick={() => setPopupShown(!popupShown)}>
                                     {popupShown &&
                                     <Popup closePopup={() => setPopupShown(false)} className={styles.recordPopup}>
                                         {menuOptions}
                                     </Popup>
                                     }
                                 </div>
                             )}
                         </div>
                     </>}>
            <div className={styles.contentContainer}>
                <span className={styles.title}>
                    {record.title}
                </span>
                <div className={styles.dataContainer}>
                    <div className={styles.player}>
                        <AudioPlayer
                            trackId={record.id}
                            entityName='tracks'
                            setAudioUrl={setAudioUrl}
                        />
                    </div>
                </div>
            </div>
        </GenericCard>
    );
};

RecordCard.propTypes = {
    data: PropTypes.object,
    checkbox: PropTypes.node,
    withMenu: PropTypes.bool,
    icon: PropTypes.string,
    getMenuOptions: PropTypes.func,
    getRecordData: PropTypes.func
};

export default RecordCard;
