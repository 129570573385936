import { api } from "../App";
import {RADIO_URL} from "./config";

const COLORS_URL = `${RADIO_URL}/colors`;
const OBJECT_STATUSES_URL = `${RADIO_URL}/object-statuses`;
const OBJECTS_URL = `${RADIO_URL}/objects`;
const ORDER_URL = `${RADIO_URL}/orders`;
const MEDIAPLAN_LIST_URL = `${RADIO_URL}/mediaplans`;
const OBJECT_CREATE_METADATA_URL = `${RADIO_URL}/items`;

export const fetchRadioColors = async () => {
    return api.get(COLORS_URL);
};

export const fetchRadioObjectStatuses = async () => {
    return api.get(OBJECT_STATUSES_URL);
};

export const fetchRadioObjectList = async (params) => {
    return api.get(OBJECTS_URL, {params});
};

export const fetchRadioObject = async (id) => {
    return api.get(`${OBJECTS_URL}/${id}`);
};

export const fetchRadioMediaplanList = async (params) => {
    return api.get(MEDIAPLAN_LIST_URL, {params});
};

export const fetchRadioMediaplan = async (id) => {
    return api.get(`${MEDIAPLAN_LIST_URL}/${id}`);
};

export const deleteRadioMediaplan = async (id) => {
    return api.delete(`${MEDIAPLAN_LIST_URL}/${id}`);
}

export const postRadioMediaplan = async (data) => {
    return api.post(`${MEDIAPLAN_LIST_URL}`, data);
};

export const patchRadioMediaplan = async (data, id) => {
    return api.patch(`${MEDIAPLAN_LIST_URL}/${id}`, data);
};

export const fetchPriorityAds = async (id) => {
    return api.get(`${OBJECTS_URL}/${id}/announcements`);
};

export const playPriorityAd = async (objectId, adId) => {
    return api.post(`${OBJECTS_URL}/${objectId}/announcements/${adId}/play`)
}

export const fetchObjectCreateMetadata = () => {
    return api.get(`${OBJECT_CREATE_METADATA_URL}`);
}

export const postCreateObject = (formData) => {
    return api.post(`${ORDER_URL}/`, formData);
}

export const fetchObjectVolumeLevels = (id) => {
    return api.get(`${OBJECTS_URL}/${id}/volume`);
}

export const putObjectVolumeLevel = (id, type, volume) => {
    return api.put(`${OBJECTS_URL}/${id}/volume/${type}`, {
        volume
    });
}

export const fetchObjectBroadcastPauses = (id) => {
    return api.get(`${OBJECTS_URL}/${id}/mute`);
}

export const deleteObjectBroadcastPauses = (id, pauseId) => {
    return api.delete(`${OBJECTS_URL}/${id}/mute/${pauseId}`);
}

export const postObjectBroadcastPause = (id, data) => {
    return api.post(`${OBJECTS_URL}/${id}/mute`, data);
}

export const getTrackOnAir = (objectId) => {
    return api.get(`${OBJECTS_URL}/${objectId}/track-on-air`);
}

export const getBroadcastReference = (objectId, dateTimeTo) => {
    return api.get(`${OBJECTS_URL}/${objectId}/timeline`, {params: {dateTimeTo}})
}

export const uploadMicrophoneAudio = (objectId, data) => {
    return api.post(
        `${OBJECTS_URL}/${objectId}/play-stream`,
        data,
        {
            headers: {
                'Content-Type': 'audio/mpeg',
            }
        }
    );
}

export const createPriorityAd = (objectId, data) => {
    return api.post(`${OBJECTS_URL}/${objectId}/announcements`, data);
}

export const deletePriorityAd = (objectId, id) => {
    return api.delete(`${OBJECTS_URL}/${objectId}/announcements/${id}`);
}

export const putPriorityAd = (objectId, data) => {
    return api.put(`${OBJECTS_URL}/${objectId}/announcements/${data.id}`, {
        title: data.title,
        favorite: data.favorite
    });
}

export const getHoursOnAir = (objectId, type) => {
    return api.get(`${OBJECTS_URL}/${objectId}/hours/${type}`)
}

export const putHoursOnAir = (objectId, type, data) => {
    return api.put(`${OBJECTS_URL}/${objectId}/hours/${type}`, data)
}

export const getWorkload = () => {
     return api.get(`${RADIO_URL}/workload`)
}

export const unmuteObject = (objectId) => {
    return api.get(`${OBJECTS_URL}/${objectId}/unmute`);
};