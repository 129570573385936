import { createSelector } from "reselect";

const studioSelector = (state) => state.studio;

export const studioMetadataSelector = createSelector(
    studioSelector,
    ({metadata}) => metadata,
);

export const createStudioFiltersSelector = entity => createSelector(
    studioSelector,
    ({filters}) => filters[entity]
);

export const createStudioDataSelector = entity => createSelector(
    studioSelector,
    ({data}) => data[entity]
);

export const studioRecordSelector = createSelector(
    studioSelector,
    ({record}) => record,
)

export const tracksSelector = createSelector(
    studioSelector,
    ({tracks}) => tracks,
)
