import React from 'react';
import PropTypes from 'prop-types';
import styles from './BroadcastReferenceItem.module.scss';
import classNames from 'class-names';
import moment from "moment";

const BroadcastReferenceItem = ({data, isFirstOfColor, isLastOfColor}) => {
    const getMarkerClassName = (index) => ({
        [styles.first]: isFirstOfColor,
        [styles.last]: isLastOfColor
    });

    return (
        <div className={classNames(styles.container)}>
            <div className={classNames(styles.colorMarkerContainer, getMarkerClassName())}>
                <div className={styles.colorMarker}
                     style={{backgroundColor: data.color}}/>
            </div>
            <div className={styles.infoText}>
                {data.releaseTime}
            </div>
            <div className={classNames(styles.infoText, styles.infoTitle)}>
                {data.title}
            </div>
            <div className={styles.infoText}>
                {moment.utc(moment.duration(Number(data.duration), 'milliseconds').asMilliseconds()).format('mm:ss')}
            </div>
        </div>
    );
};

BroadcastReferenceItem.propTypes = {
    data: PropTypes.object.isRequired
};

export default BroadcastReferenceItem;
