import React, { useState } from 'react';
import styles from './BroadcastActions.module.scss';
import { Link } from "react-router-dom";
import Popup from "../../../Popup";
import {useRequest} from "ahooks";
import {getWorkload} from "../../../../api/radio-api";

const BroadcastActions = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [workload, setWorkload] = useState(0);

    useRequest(getWorkload, {
        onSuccess: (response) => {
            setWorkload(response.data);
        },
    });

    return (
        <div className={styles.container}>
            <span className={styles.loadStatusContainer}>
                Загруженность эфира:
                <div className={styles.loadStatusValue}>
                    {workload}%
                </div>
            </span>
            <Link to='/radio/mediaplan/create' className={styles.createButton}>
                Создать медиаплан
            </Link>
            <div className={styles.sortIcon} onClick={() => setShowPopup(!showPopup)}>
                {showPopup && <Popup closePopup={() => setShowPopup(false)}
                                     containerClassName={styles.popup}>
                    <div className='popup-menu-item'>
                        Актуальные
                    </div>
                    <div className='popup-menu-item'>
                        Неактуальные
                    </div>
                </Popup>}
            </div>
        </div>
    );
};

export default BroadcastActions;
