import React from 'react';
import { Calendar } from 'react-date-range';
import { ru } from "react-date-range/src/locale";
import styles from './NewCalendar.module.scss';
import classNames from 'class-names';
import moment from "moment";

const NewCalendar = ({ className, currentDate, onChange }) => {

    console.log(onChange)

    return (
        <div className={classNames(styles.container, className)}>
            <Calendar
                className={styles.picker}
                minDate={new Date(moment(new Date()).add(1, 'days'))}
                locale={ru}
                date={currentDate}
                onChange={onChange}
            />
        </div>
    );
};

NewCalendar.propTypes = {};

export default NewCalendar;
