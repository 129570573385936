import { useDispatch, useSelector } from "react-redux";
import { radioDataSelector } from "../../redux/modules/radio/radio-selectors";
import { RADIO_ENTITY } from "../../constants/radioEntities";
import React from "react";
import { isRadioPageLoaded } from "../../redux/modules/radio/radio-helpers";
import { useRadioMetadata } from "./useRadioMetadata";
import { loadRadioData } from "../../redux/modules/radio/radio-actions";
import {deletePriorityAd, putPriorityAd} from "../../api/radio-api";

export const useRadioPriorityAds = (id) => {
    const dispatch = useDispatch();
    useRadioMetadata();

    const entityData = useSelector(radioDataSelector);
    const loadPriorityAds = (id) => dispatch(loadRadioData(RADIO_ENTITY.PRIORITY_ADS, id));

    React.useEffect(() => {
        loadPriorityAds(id)
    }, [id]);

    const isLoaded = isRadioPageLoaded(RADIO_ENTITY.PRIORITY_ADS, entityData);

    const onEdit = async (data) => {
        await putPriorityAd(id, data);
        loadPriorityAds(id);
    }

    const onDelete = async (adId) => {
        await deletePriorityAd(id, adId);
        loadPriorityAds(id);
    }

    return {data: entityData, isLoaded, onDelete, onEdit};
}
