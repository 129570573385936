import { createStudioDataSelector, createStudioFiltersSelector, studioMetadataSelector } from "./studio-selectors";
import {
    deleteArchiveTracks,
    fetchStudioCategories, fetchStudioData, fetchStudioRecord,
    fetchStudioStatuses,
    fetchStudioTypes,
    fetchStudioVoices, fetchTracks, postArchiveTracks, postTrackOrder
} from "../../../api/studio-api";
import { SET_STUDIO_RECORD_DATA } from "../../actions/action-types";
import { showModal } from "../../actions/ui/modal/modal";

export const SET_STUDIO_METADATA = 'SET_STUDIO_METADATA';
export const SET_STUDIO_FILTERS = 'SET_STUDIO_FILTERS';
export const RESET_STUDIO_FILTERS = 'RESET_STUDIO_FILTERS';
export const SET_STUDIO_DATA = 'SET_STUDIO_DATA';
export const TRACKS = 'TRACKS';

const setStudioMetadata = (metadata) => ({
    type: SET_STUDIO_METADATA,
    payload: metadata,
})

const setTracks = (tracks) => ({
    type: TRACKS,
    payload: tracks,
})

const setStudioFilters = (entity, filters) => ({
    type: SET_STUDIO_FILTERS,
    payload: { entity, filters },
});

export const resetStudioFilters = (entity) => ({
    type: RESET_STUDIO_FILTERS,
    payload: entity,
});

const setStudioData = (entity, data) => ({
    type: SET_STUDIO_DATA,
    payload: { entity, data },
});

const setStudioRecord = (data) => ({
    type: SET_STUDIO_RECORD_DATA,
    payload: data,
});

const handleStudioError = (error, dispatch) => {
    if (error.response && error.response.data) {
        console.error(error.response.data);
        if (error.response.data.errorMessage && dispatch) {
            dispatch(showModal({
                title: 'Ошибка',
                text: error.response.data.errorMessage,
                cancelText: 'Закрыть',
                submitText: null,
            }))
        }
    } else {
        console.error(error);
    }
}

export const loadTracks =  (options) => async (dispatch) => {
    try {
        const params = {
            _page: 0,
            _pageSize: 10,
            ...options,
        };

        const { data } = await fetchTracks(params);

        dispatch(setTracks(data));
    } catch (e) {
        dispatch(handleStudioError(e, dispatch));
    }
}

export const loadStudioMetadata = () => async (dispatch, getState) => {
    const currentMetadata = studioMetadataSelector(getState());

    if (currentMetadata) {
        return;
    }

    try {

        const { data: categories } = await fetchStudioCategories();
        const { data: statuses } = await fetchStudioStatuses();
        const { data: types } = await fetchStudioTypes();
        const { data: voices } = await fetchStudioVoices();

        const metadata = {
            categories,
            statuses,
            types,
            voices
        };

        dispatch(setStudioMetadata(metadata));
    } catch (e) {
        handleStudioError(e, dispatch);
    }
};

export const loadStudioData = (entity, page) => async (dispatch, getState) => {
    const currentData = createStudioDataSelector(entity)(getState());
    const filters = createStudioFiltersSelector(entity)(getState());
    dispatch(setStudioData(entity, {
        ...currentData,
        list: [],
        isLoading: true,
    }));

    try {
        const { data } = await fetchStudioData(entity, {
            ...filters,
            _page: page !== undefined ? page : currentData.pageNumber,
            _pageSize: currentData.pageSize,
            _sort: entity === 'track-orders' ? '-createdDate' : undefined,
        });

        dispatch(setStudioData(entity, {
            ...currentData,
            list: data.items,
            isLoading: false,
            pageNumber: data.pageNumber,
            totalPages: data.totalPages,
            totalRecords: data.totalElements,
        }))
    } catch (e) {
        handleStudioError(e, dispatch);
    }
}

export const saveStudioFilters = (entity, filters) => async (dispatch) => {
    dispatch(setStudioFilters(entity, filters));
    dispatch(loadStudioData(entity));
};

export const loadStudioRecord = (entity, recordId) => async (dispatch) => {
    dispatch(setStudioRecord({
        isLoading: true,
        recordId,
        entity,
    }));
    try {
        const { data } = await fetchStudioRecord(entity, recordId);
        dispatch(setStudioRecord({
            isLoading: false,
            data,
        }))
    } catch (e) {
        handleStudioError(e, dispatch);
    }
};

export const addTracksToArchive = (trackIds, entityName) => async (dispatch) => {
    try {
        const { data } = await postArchiveTracks(trackIds);
        if (entityName) {
            dispatch(loadStudioData(entityName));
        }
        dispatch(showModal({
            title: "Добавление в архив",
            text: "Ролик был успешно добавлен в архив",
            submitText: "Закрыть",
            cancelText: null
        }));
    } catch (e) {
        handleStudioError(e);
        let errorMessage = "Ошибка добавления в архив"

        if (e.response.data.errorMessage) {
            errorMessage = e.response.data.errorMessage;
        }
        dispatch(showModal({
            title: "Добавление в архив",
            text: errorMessage,
            submitText: "Закрыть",
            cancelText: null
        }));
    }
};

export const removeTracksFromArchive = (trackIds, entityName) => async (dispatch) => {
    try {
        const { data } = await deleteArchiveTracks(trackIds);
        if (entityName) {
            dispatch(loadStudioData(entityName));
        }
        dispatch(showModal({
            title: "Удаление из архива",
            text: "Ролик был успешно удален из архива",
            submitText: "Закрыть",
            cancelText: null
        }));
    } catch (e) {
        handleStudioError(e);

        let errorMessage = "Ошибка удаления из архива"
        if (e.response.data.errorMessage) {
            errorMessage = e.response.data.errorMessage;
        }
        dispatch(showModal({
            title: "Удаление из архива",
            text: errorMessage,
            submitText: "Закрыть",
            cancelText: null
        }));
    }
    ;
};

export const orderNewTrack = () => async (dispatch) => {
    try {
        const { data } = await postTrackOrder();
    } catch (e) {
        handleStudioError(e, dispatch);
    }
}
