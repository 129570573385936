import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from './RecordActions.module.scss';
import Popup from "../../../Popup";
import { PAGE_TYPE } from "../../../../constants/pageTypes";
import { showModal } from "../../../../redux/actions/ui/modal/modal";
import { connect } from "react-redux";

const mapDispatchToProps = (dispatch) => ({
    showModal: (data) => dispatch(showModal(data))
});

const RecordActions = ({ pageType, showModal, orderTrack, onArchive }) => {
    const [actionsOpen, setActionsOpen] = useState(false);

    const onOrderTrack = () => {
        orderTrack();
        showModal({
            title: "Заказ ролика",
            text: "Вами создан заказ на производство аудиоролика. В ближайшее время с вами свяжется наш менеджер.",
            submitText: "Закрыть",
            cancelText: null,
        });
    }

    return (
        <div className={styles.container}>
            <div className={styles.addButton}
                 onClick={onOrderTrack}>
                Заказ ролика
            </div>
            {PAGE_TYPE.ORDERS !== pageType && (
                <div className={styles.dropdownButton} onClick={() => setActionsOpen(!actionsOpen)}>
                    Действия
                    {actionsOpen &&
                    <Popup closePopup={() => setActionsOpen(false)}
                           containerClassName={styles.dropdownPopupContainer}
                           className={styles.dropdownPopup}>
                        {pageType === PAGE_TYPE.ARCHIVE &&
                        <div className={styles.popupButton} onClick={onArchive}>
                            Убрать из архива
                        </div>}
                        {pageType === PAGE_TYPE.RECORDS &&
                        <div className={styles.popupButton} onClick={onArchive}>
                            Добавить в архив
                        </div>}
                    </Popup>}
                </div>
            )}
        </div>
    );
};

RecordActions.propTypes = {
    pageType: PropTypes.string,
};

export default connect(undefined, mapDispatchToProps)(RecordActions);
