import axios from 'axios';
import {api} from "../App";
import {RADIO_URL} from "./config";

export const BACKGROUND_URL = RADIO_URL;
const PLAYLISTS = `${BACKGROUND_URL}/playlists`;
const OBJECTS = `${BACKGROUND_URL}/objects`;
const TIMELINE = `${BACKGROUND_URL}/objects`;
const PURCHASED = `${BACKGROUND_URL}/objects`;

export const fetchBackgroundPlaylists = async (params) => {
    return await axios.get(PLAYLISTS, { params });
};

export const fetchCurrentPlaylist = async (id, params) => {
    return await axios.get(`${PLAYLISTS}/${id}`, {params});
};

export const fetchObjectPlaylists = async (objectId, params) => {
    return await axios.get(`${OBJECTS}/${objectId}/playlists`, { params });
};

export const fetchTimelinePlaylists = async (objectId, params) => {
    return await axios.get(`${TIMELINE}/${objectId}/timeline`, { params });
};

export const fetchPurchasedPlaylists = async (objectId, params) => {
    return await axios.get(`${PURCHASED}/${objectId}/purchased-playlists`, { params });
};

export const postObjectPlaylist = async (objectId, params) => {
    return await api.post(`${OBJECTS}/${objectId}/playlists`, params);
};

export const deleteObjectPlaylist = async (objectId, id) => {
    return await api.delete(`${OBJECTS}/${objectId}/playlists/${id}`);
};

