import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './ObjectCreateScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import {useSetCurrentSection} from "../../../../hooks/useSetCurrentSection";
import {PAGE_SUBSECTIONS} from "../../../../constants/pageTypes";
import Input from "../../../../components/common/Input";
import Button from "../../../../components/common/Button";
import Select from "../../../../components/common/Select";
import Checkbox from "../../../../components/common/Checkbox/NewCheckbox";
import Multiselect from "../../../../components/common/Multiselect";
import {useDispatch, useSelector} from "react-redux";
import {loadObjectCreateMetadata} from "../../../../redux/modules/radio/radio-actions";
import {objectCreateMetadataSelector} from "../../../../redux/modules/radio/radio-selectors";
import {postCreateObject} from "../../../../api/radio-api";
import {useHistory} from "react-router-dom";
import {showModal} from "../../../../redux/actions/ui/modal/modal";

const breadcrumbsRoutes = [
    {
        name: 'Корпоративное радио',
        href: '#'
    },
    {
        name: 'Объекты',
        href: '/radio/objects'
    },
    {
        name: 'Заказ объекта',
        href: '/radio/object/create'
    }
];

const ObjectCreateScene = props => {
    useSetCurrentSection(PAGE_SUBSECTIONS.OBJECTS);
    const dispatch = useDispatch();
    const history = useHistory();

    React.useEffect(() => {
        dispatch(loadObjectCreateMetadata());
    }, [dispatch]);

    const formMetadata = useSelector(objectCreateMetadataSelector);

    const [formData, setFormData] = React.useState({lines: []});

    const onChange = (e, passedValue) => {
        const value = passedValue === undefined ? e.target.value : passedValue;
        setFormData({
            ...formData,
            [e.target.name]: value,
        });
    };

    const onChangeMetadata = (e, passedValue) => {
        const value = passedValue === undefined ? e.target.value : passedValue;
        const metadataItem = formMetadata.find(item => item.id === e.target.name);
        let newEntry;

        if (e.target.name === 'tariff') {
            newEntry = {
                itemId: 'tariff',
                qty: Number(value),
            }
            const newLines = [...formData.lines.filter(item => item.itemId !== 'tariff'), newEntry];

            setFormData({
                ...formData,
                lines: newLines,
            });
            return;
        } else if (metadataItem.foreignId === 'checkbox') {
            if (value) {
                newEntry = {
                    itemId: metadataItem.id,
                    qty: 1,
                }
            } else {
                newEntry = {
                    itemId: metadataItem.id,
                    qty: 0,
                }
            }
        } else if (metadataItem.type === 'OPTION') {
            newEntry = {
                itemId: metadataItem.id,
                qty: Number(value),
            }
        }

        if (newEntry) {
            const newLines = [...formData.lines.filter(item => item.itemId !== metadataItem.id), newEntry];

            setFormData({
                ...formData,
                lines: newLines,
            });
        }
    };

    const renderCustomFormElements = () => {
        return formMetadata.map(metadataItem => {
            if (metadataItem.type === 'OPTION') {
                const formLine = formData.lines.find(item => item.itemId === metadataItem.id)

                if (metadataItem.foreignId === 'checkbox') {
                    return (<React.Fragment key={metadataItem.id}>
                            <Checkbox
                                type="card"
                                checked={Boolean(formLine?.qty) || false}
                                name={metadataItem.id}
                                onChange={(e) => {
                                    onChangeMetadata(e, e.target.checked)
                                }}
                                className={styles.checkboxWrapper}
                            >
                            <span className={styles.checkboxLabel}>
                                {metadataItem.name}
                            </span>
                            </Checkbox>
                            <div className={styles.hintText}>
                                {metadataItem.description}
                            </div>
                        </React.Fragment>
                    )
                }

                return (
                    <React.Fragment key={metadataItem.id}>
                        <Input
                            onChange={onChangeMetadata}
                            type={"number"}
                            value={formLine?.qty || ''}
                            name={metadataItem.id}
                            label={metadataItem.name}
                            inputClassName={styles.input}
                            className={styles.inputWrapper}
                        />
                        <div className={styles.hintText}>
                            {metadataItem.description}
                        </div>
                    </React.Fragment>
                );
            }
        })
    }

    useEffect(() => {
        const tariffItems = formMetadata?.filter(item => item.type === 'TARIFF');

        if (tariffItems && tariffItems?.length) {
            const newLine = {
                itemId: 'tariff',
                qty: Number(tariffItems?.[0]?.id)
            }
            setFormData({
                ...formData,
                lines: [newLine]
            });
        }
    }, [formMetadata])

    const renderTariffBlock = () => {
        const tariffItems = formMetadata.filter(item => item.type === 'TARIFF');

        if (!tariffItems.length) {
            return null;
        }

        const tariffOptions = tariffItems.map(tariffItem => ({
            name: tariffItem.name,
            value: tariffItem.id
        }))

        const formLine = formData.lines.find(item => item.itemId === 'tariff')

        return (<>
            <Select
                onChange={onChangeMetadata}
                selected={String(formLine?.qty)}
                wide
                label="Тариф"
                options={tariffOptions}
                name="tariff"
            />
            <div className={styles.hintText}>
                {formLine && tariffItems.find(item => item.id === String(formLine?.qty))?.description}
            </div>
        </>)
    }

    const onSubmit = async () => {
        try {
            const requestData = {
                description: {
                    ...formData,
                },
                lines: formData?.lines?.filter(item => item.itemId !== 'tariff'),
            };

            requestData.lines.push({
                itemId: String(formData?.lines?.find(item => item.itemId === 'tariff')?.qty)
            })

            await postCreateObject(requestData);
            history.push('/radio/objects');
        } catch (e) {
            dispatch(showModal({
                title: 'Ошибка',
                text: e.response.data.errorMessage,
                cancelText: 'Закрыть',
                submitText: null,
            }))
        }
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <div className={styles.objectForm}>
                <div className={styles.desktopCol}>
                    <Input
                        onChange={onChange}
                        name="name"
                        label="Название объекта"
                        inputClassName={styles.input}
                        className={styles.inputWrapper}
                    />
                    <Input
                        onChange={onChange}
                        name="region"
                        label="Регион"
                        inputClassName={styles.input}
                        className={styles.inputWrapper}
                    />
                    <Input
                        onChange={onChange}
                        name="city"
                        label="Город"
                        inputClassName={styles.input}
                        className={styles.inputWrapper}
                    />
                    <Input
                        onChange={onChange}
                        name="address"
                        label="Адрес"
                        inputClassName={styles.input}
                        className={styles.inputWrapper}
                    />
                    <Input
                        onChange={onChange}
                        name="comment"
                        type="textarea"
                        label="Описание"
                        inputClassName={styles.input}
                        className={styles.inputWrapper}
                        maxLength={350}
                    />
                </div>
                <div className={styles.desktopCol}>
                    {formMetadata && renderCustomFormElements()}
                    {formMetadata && renderTariffBlock()}
                </div>
                <Button
                    onClick={onSubmit}
                    className={styles.button}
                >
                    Заказать
                </Button>
            </div>
        </div>
    );
};

ObjectCreateScene.propTypes = {};

export default ObjectCreateScene;
