import React, {useEffect, useState, useCallback} from 'react';
import styles from './BackgroundScene.module.scss'
import Breadcrumbs from "../../../../components/Breadcrumbs";
import {Link, useParams} from "react-router-dom";
import btnImage from "../../../../img/icons/calendar-icon.svg"
import NewCalendar from "../../../../components/common/NewCalendar";
import classNames from 'class-names';
import PlaylistItem from "../../../../components/PlaylistItem";
import { range } from "../../../../utils/range";
import addPlaylistIcon from "../../../../img/icons/add-playlist-btn.svg"
import { useSetCurrentSection } from "../../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../../constants/pageTypes";
import {useDispatch, useSelector} from "react-redux";
import { objectPlaylists } from "../../../../redux/modules/background/background-selectors";
import {
    loadObjectPlaylists,
    loadTimelinePlaylists,
    removeObjectPlaylist
} from "../../../../redux/modules/background/background-actions";
import Spinner from "../../../../components/Spinner";
import moment from "moment";
import {useRadioObjects} from "../../../../hooks/radio/useRadioObjects";

const BackgroundScene = () => {
    useSetCurrentSection(PAGE_SUBSECTIONS.OBJECTS);
    let { objectId } = useParams();

    const breadcrumbsRoutes = [{
        name: 'Корпоративное радио',
        href: '#'
    }, {
        name: 'Объекты',
        href: '/radio/objects'
    }, {
        name: 'Страница объекта',
        href: `/radio/objects/${objectId}`
    }, {
        name: 'Фон',
        href: `/radio/object/background`
    }];

    const [currentDate, setCurrentDate] = useState(new Date());
    const [formCalendar, onOpen] = useState(false);
    const playlists = useSelector(objectPlaylists);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        const params = {
            date: moment(currentDate).format('YYYY-MM-DD'),
            usedByDefault: false
        }

        dispatch(loadObjectPlaylists(objectId, params));
    }, [dispatch, objectId, currentDate]);

    useEffect(() => {
        setIsLoading(false);
    }, [playlists]);

    const onDateChange = (newDate) => {
        setCurrentDate(newDate);
    }

    const onDelete = useCallback(async (objectId, id) => {
        await dispatch(removeObjectPlaylist(objectId, id));
        setIsLoading(true);

        const params = {
            date: moment(currentDate).format('YYYY-MM-DD'),
            usedByDefault: false
        }

        await dispatch(loadObjectPlaylists(objectId, params));
    }, [currentDate, dispatch]);

    if (isLoading || !playlists) {
        return (
            <div className={styles.spinnerContainer}>
                <Spinner/>
            </div>
        )
    }

    return (
        <div className={styles.container}
             onClick={() => formCalendar === true && onOpen(formCalendar)}>
            <Breadcrumbs routes={breadcrumbsRoutes} />
                <div className={styles.content}>
                    {/*<p>По умолчанию: Плейлист №1</p>*/}
                    <div className={styles.elements}>
                        <span className={styles.date}>{currentDate.toLocaleDateString()} год</span>
                        <button className={styles.btn}
                                onClick={() => onOpen(!formCalendar)}>
                            <img src={btnImage} alt='button'/>
                        </button>
                    </div>
                    <div className={classNames(styles.dateRange, {[styles.calendar]: formCalendar})}>
                        <NewCalendar className={styles.dates} currentDate={currentDate} onChange={onDateChange}/>
                    </div>
                    {range(24).map(position => {
                        // const res = playlists?.filter(item => !item.usedByDefault);
                        const { playlist } = playlists?.length ? playlists[0] : {};
                        position < 10 ? position = '0' + position + ':00' : position += ":00"

                        if (isLoading) {
                            return (
                                <div className={styles.spinnerContainer}>
                                    <Spinner />
                                </div>
                            )
                        }

                        return (
                            <div className={styles.playlistTimelineItem} key={position}>
                                <span className={styles.hours}>{position}</span>
                                {playlists?.length ? (
                                    <PlaylistItem
                                        type={playlist.type}
                                        title={playlist.title}
                                        image={playlist.miniatureImage}
                                        bundleId={playlists[0]?.id}
                                        id={playlists[0]?.playlistId}
                                        dateTimeFrom={playlists?.dateTimeFrom}
                                        objectId={objectId}
                                        onDelete={onDelete}
                                    />
                                ) : (
                                    <Link className={styles.href} to={`/radio/object/background/${objectId}/playlists`}>
                                        <div className={styles.playlistEmpty}>
                                            <button className={styles.add}>
                                                <img src={addPlaylistIcon} alt='fsd'/>
                                            </button>
                                        </div>
                                    </Link>
                                )}
                            </div>
                        )}
                    )}
                </div>
        </div>
    );
};

export default BackgroundScene

