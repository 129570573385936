import React, {useState} from 'react';
import styles from './BroadcastReferenceScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import ItemListTitle from "../../../../components/ItemListTitle";
import objectIcon from "../../../../img/icons/object-dark-grey-icon.svg";
import RecordStatus from "../../../../components/Cards/RecordCard/RecordStatus";
import {RECORD_ONLINE_STATUS, RECORD_STATUS} from "../../../../constants/recordTypes";
import Button from "../../../../components/common/Button";
import Input from "../../../../components/common/Input";
import TimePicker from "../../../../components/TimePicker";
import classNames from 'class-names';
import BroadcastReference from "../../../../components/BroadcastReference";
import {REFERENCE_DATA, REFERENCE_TEST_DATA} from "../../../../constants/referenceTestData";
import {useSetCurrentSection} from "../../../../hooks/useSetCurrentSection";
import {PAGE_SUBSECTIONS} from "../../../../constants/pageTypes";
import {getBroadcastReference, getTrackOnAir} from "../../../../api/radio-api";
import {useRequest} from "ahooks";
import {useParams} from "react-router-dom";
import moment from "moment";
import {radioDataSelector, radioParamsSelector} from "../../../../redux/modules/radio/radio-selectors";
import {loadRadioData, setRadioParams} from "../../../../redux/modules/radio/radio-actions";
import {RADIO_ENTITY} from "../../../../constants/radioEntities";
import {connect, useDispatch} from "react-redux";
import {getOptionalValue} from "../../../../utils/getOptionalValue";
import {showModal} from "../../../../redux/actions/ui/modal/modal";

const getBreadcrumbsRoutes = (id) => [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Объекты',
    href: '/radio/objects'
}, {
    name: 'Страница объекта',
    href: `/radio/objects/${id}`,
}, {
    name: 'Эфирная справка',
    href: '/radio/object/reference'
}];

const mapStateToProps = (state) => ({
    entityData: radioDataSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadObjectData: (id) => dispatch(loadRadioData(RADIO_ENTITY.OBJECT, id)),
})

const BroadcastReferenceScene = ({entityData, loadObjectData}) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    useSetCurrentSection(PAGE_SUBSECTIONS.OBJECTS);
    const [referenceData, setReferenceData] = useState();
    const [selectedDate, setSelectedDate] = useState(moment(new Date()).set({minutes: 0, seconds: 0}).toDate());

    const [datepickerVisible, setDatepickerVisible] = useState(false);

    React.useEffect(() => {
        loadObjectData(id)
    }, [id, loadObjectData]);

    useRequest(() => getBroadcastReference(id, moment(selectedDate).format('YYYY-MM-DDTHH:mm:ss')), {
        refreshDeps: [id, selectedDate],
        onSuccess: (response) => {
            setReferenceData(response.data);
        },
        onError: (error => {
            dispatch(showModal({
                title: 'Ошибка',
                text: error.response.data.errorMessage,
                cancelText: 'Закрыть',
                submitText: null,
            }))
        })
    });

    const objectInfo = entityData && entityData.data;

    const onTimeChange = (e) => {
        const hours = e.target.value.split(':')[0];

        setSelectedDate(selectedDate => moment(selectedDate).set({
            hours,
            minutes: 0,
        }).toDate());
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={getBreadcrumbsRoutes(id)}/>
            <div className={styles.pageHeader}>
                <ItemListTitle icon={objectIcon}
                               className={styles.pageTitle}
                               title={getOptionalValue(objectInfo, 'title')}>
                    ID {id} <RecordStatus
                    onlineStatus={objectInfo?.online ? RECORD_ONLINE_STATUS.ONLINE : RECORD_ONLINE_STATUS.OFFLINE}
                    status={objectInfo?.status?.id === 'ACTIVE' ? RECORD_STATUS.READY : undefined}/>
                </ItemListTitle>
                <div className={styles.formControls}>
                    Дата и время
                    <Button onClick={() => setDatepickerVisible(!datepickerVisible)} className={styles.formButton}>
                        {datepickerVisible ? "Скрыть" : "Добавить"}
                    </Button>
                </div>
                <div className={classNames(styles.datepickerContainer, {[styles.visible]: datepickerVisible})}>
                    <TimePicker date={selectedDate}
                                key={selectedDate}
                                onChange={
                                    ({hours, minutes}) => setSelectedDate(moment(selectedDate).set({
                                        hours,
                                        minutes: 0,
                                    }).toDate())
                                }
                                className={classNames(styles.timePicker, styles.hideDesktop)}/>
                    <Input name={'startDate'}
                           value={moment(selectedDate).format('YYYY-MM-DD')}
                           onChange={e => setSelectedDate(moment(e.target.value).set({
                               hours: moment(selectedDate).hours(),
                               minutes: 0,
                           }).toDate())}
                           className={styles.dateInput}
                           label={'Дата'}
                           type={'date'}/>
                    <Input name={'startTime'}
                           className={classNames(styles.dateInput, styles.hideMobile)}
                           value={moment(selectedDate).format('HH:mm')}
                           onChange={onTimeChange}
                           label={'Время'}
                           type={'time'}/>
                    <Button className={classNames(styles.datepickerButton, styles.hideDesktop)}>
                        Применить
                    </Button>
                </div>
            </div>
            {referenceData && <BroadcastReference data={referenceData}/>}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastReferenceScene);
