import React, { useEffect, useState } from 'react';
import styles from './RecordScene.module.scss';
import classNames from "class-names";
import {Link, Redirect, useHistory} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import RecordInfoSection from "../../components/RecordScene/InfoSection";
import {
    addTracksToArchive,
    loadStudioRecord,
    removeTracksFromArchive
} from "../../redux/modules/studio/studio-actions";
import { studioRecordSelector } from "../../redux/modules/studio/studio-selectors";
import { STUDIO_ENTITY, STUDIO_URL_ENTITY_MAP } from "../../constants/studioEntities";
import Spinner from "../../components/Spinner";
import AudioPlayer from "../../components/AudioPlayer";
import { useSetCurrentSection } from "../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../constants/pageTypes";
import {confirmOrder, excludeFromAir, rejectOrder} from "../../api/studio-api";
import moment from "moment";
import {handleRadioError} from "../../redux/modules/radio/radio-actions";

const mapStateToProps = (state) => ({
    record: studioRecordSelector(state)
});

const mapDispatchToProps = dispatch => ({
    loadRecord: (entity, id) => dispatch(loadStudioRecord(entity, id)),
    addToArchive: (ids, entity) => dispatch(addTracksToArchive(ids)),
    removeFromArchive: (ids, entity) => dispatch(removeTracksFromArchive(ids)),
});

const RecordScene = ({ match, record, loadRecord, addToArchive, removeFromArchive }) => {
    const { data, isLoading } = record;
    const { entity: entityUrl } = match.params;
    const entity = STUDIO_URL_ENTITY_MAP[entityUrl];
    useSetCurrentSection(PAGE_SUBSECTIONS[entityUrl.toUpperCase()]);
    const history = useHistory();
    const dispatch = useDispatch();

    const [audioUrl, setAudioUrl] = useState(null);

    useEffect(() => {
        const { id } = match.params;

        if (!(record.recordId === id) || !data && !record.isLoading) {
            loadRecord(entity, id);
        }
    }, [loadRecord, match.params]);

    const [infoOpen, setInfoOpen] = useState(false);

    if (!match.params.id)
        return <Redirect to={'/404'}/>;

    const toggleArchive = () => {
        if (record.archived) {
            removeFromArchive([record.recordId]);
        } else {
            addToArchive([record.recordId]);
        }

        loadRecord(entity, record.recordId);
    }

    const getAir = () => {
        if (entity !== 'track-orders') {
            return data.onAir ? " • В ЭФИРЕ" : " • НЕ В ЭФИРЕ"
        }
    }

    const isOrderType = entity === STUDIO_ENTITY.ORDERS;

    const renderPlayer = () => {
        return (
            <div className={styles.player}>
                <div className={styles.hintText}>
                    Прослушать ролик
                </div>
                <AudioPlayer
                    trackId={!isOrderType ? record.recordId : record.data.trackId}
                    entityName={isOrderType ? 'tracks' : STUDIO_URL_ENTITY_MAP[entityUrl]}
                    setAudioUrl={setAudioUrl}
                />
            </div>
        )
    }

    const handleExcludeFromAir = async () => {
        const { id } = match.params;

        try {
            await excludeFromAir(id);
            loadRecord(entity, id);
        } catch (e) {
            dispatch(handleRadioError(e));
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.pageContainer}>
                {(isLoading || !data) ? (
                    <div className={styles.spinnerContainer}>
                        <Spinner/>
                    </div>
                ) : (
                    <div className={styles.mainContainer}>
                        <Link to={!isOrderType ? "/studio/records" : "/studio/orders"} className={styles.backLink}>
                            {!isOrderType ? 'Вернуться к списку роликов' : 'Вернуться к списку заказов'}
                        </Link>
                        <div className={styles.statusInfo}>
                            ID {record.recordId} {getAir()}
                        </div>
                        <div className={styles.flexContainer}>
                            <h1 className={styles.title}>
                                {data.title || data.description}
                            </h1>
                            {renderPlayer()}
                            <div className={styles.buttonsContainer}>
                                {data.onAir && <div className={classNames(styles.button, styles.buttonGray)} onClick={handleExcludeFromAir}>
                                    Снять с эфира
                                </div>}
                                {entity !== STUDIO_ENTITY.DEMO && !isOrderType && !record.archived &&
                                    !record.onAir && (
                                        <div onClick={toggleArchive} className={styles.button}>
                                            Добавить в архив
                                        </div>
                                )}
                                {record.archived && (
                                    <div onClick={toggleArchive} className={styles.button}>
                                        Убрать из архива
                                    </div>
                                )}
                                {isOrderType && (
                                    <div onClick={() => {
                                            confirmOrder(data.id);
                                            history.push("/studio/orders")
                                    }}
                                         className={styles.button}>
                                        Подтвердить заказ
                                    </div>
                                )}
                                {isOrderType && (
                                    <div onClick={() => {
                                            rejectOrder(data.id);
                                            history.push("/studio/orders")
                                    }}
                                         className={classNames(styles.button, styles.buttonGray)}>
                                        Отклонить заказ
                                    </div>
                                )}
                                {audioUrl && (
                                        <a href={audioUrl}
                                            target="_blank"
                                            className={styles.button}>
                                            Скачать
                                        </a>
                                )}
                            </div>
                        </div>
                        {!isOrderType && (
                            <>
                                <div className={styles.hintText}>
                                    Текст ролика
                                </div>
                                <div className={styles.hintText}>
                                    {data.description}
                                </div>
                            </>
                        )}
                        {data.onAir && <div className={styles.mobileButton} onClick={handleExcludeFromAir}>
                            Снять с эфира
                        </div>}
                        {!isOrderType &&
                            <div
                                className={classNames(styles.mobileButton, styles.mobileButtonLight,
                                    { [styles.mobileButtonActive]: infoOpen })}
                                onClick={() => setInfoOpen(!infoOpen)}>
                                Характеристики ролика
                            </div>
                        }
                    </div>
                )}
                {!isLoading && data && !isOrderType &&
                    <RecordInfoSection open={infoOpen} data={record.data} />}
            </div>
            <div className={styles.createdAt}>
                Создание ролика: {moment(record.dateCreated).format('DD.MM.YYYY')}
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordScene);
