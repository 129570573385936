import React from 'react';
import PropTypes from 'prop-types';
import { RECORD_ONLINE_STATUS, RECORD_STATUS } from "../../../../constants/recordTypes";
import styles from './RecordStatus.module.scss';
import classNames from 'class-names';

const RecordStatus = ({status, onlineStatus}) => {
    if(status !== RECORD_STATUS.READY)
        return null;

    return (
        <div className={styles.container}>
            <div className={classNames(styles.status, {[styles.statusOnline]: onlineStatus})}/>
            {onlineStatus ? "В эфире" : "Не в эфире"}
        </div>
    );
};

RecordStatus.propTypes = {
    status: PropTypes.string
};

export default RecordStatus;
