import React from 'react';
import styles from './MediaplanEditScene.module.scss';
import { useSetCurrentSection } from "../../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../../constants/pageTypes";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import Input from "../../../../components/common/Input";
import Button from "../../../../components/common/Button";
import {
    radioDataSelector,
    radioFormDataSelector,
} from "../../../../redux/modules/radio/radio-selectors";
import {
    editRadioMediaplan,
    loadRadioData,
    setRadioFormData,
} from "../../../../redux/modules/radio/radio-actions";
import { RADIO_ENTITY } from "../../../../constants/radioEntities";
import { connect } from "react-redux";
import { useRadioMetadata } from "../../../../hooks/radio/useRadioMetadata";
import Spinner from "../../../../components/Spinner";

const getBreadcrumbsRoutes = (id) => [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Эфир',
    href: '/radio/broadcast'
}, {
    name: 'Страница медиаплана',
    href: `/radio/mediaplans/${id}`,
}, {
    name: 'Редактирование',
    href: '/radio/mediaplan/edit'
}];

const mapStateToProps = (state) => ({
    entityData: radioDataSelector(state),
    formData: radioFormDataSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    setFormData: (data) => dispatch(setRadioFormData(data)),
    loadMediaplanData: (id) => dispatch(loadRadioData(RADIO_ENTITY.MEDIAPLAN, id)),
    editMediaplan: (data, id) => dispatch(editRadioMediaplan(data, id)),
})

const MediaplanEditScene = ({
                                match,
                                entityData,
                                loadMediaplanData,
                                formData,
                                setFormData,
                                editMediaplan,
                            }) => {
    const { id } = match.params;
    useSetCurrentSection(PAGE_SUBSECTIONS.BROADCAST);
    useRadioMetadata();

    const isLoaded = formData && formData.entityName === RADIO_ENTITY.MEDIAPLAN;
    const mediaplanInfo = entityData && entityData.data;

    React.useEffect(() => {
        if (!entityData || !entityData.data || entityData.data.id !== Number(id)) {
            loadMediaplanData(id);
            return;
        }

        if (!formData || formData.entityName !== RADIO_ENTITY.MEDIAPLAN) {
            setFormData({
                entityName: RADIO_ENTITY.MEDIAPLAN,
                data: {},
            });
        }
    }, [entityData]);

    const data = isLoaded && entityData.data;

    const onChange = ({ target }) => {
        setFormData({
            ...formData,
            data: {
                ...formData.data,
                [target.name]: target.value,
            }
        });
    };

    const onSave = () => {
        editMediaplan(formData.data, id);
        setFormData({});
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={getBreadcrumbsRoutes(id)}/>
            {isLoaded ?
                (<div className={styles.form}>
                    <Input
                        name="title"
                        label="Название медиаплана"
                        inputClassName={styles.nameInput}
                        onChange={onChange}
                        defaultValue={data.title}
                    />
                    <Input
                        name="description"
                        type="textarea"
                        defaultValue={data.description}
                        onChange={onChange}
                        label="Описание"
                    />
                </div>)
                : (<div className={styles.spinnerContainer}>
                    <Spinner/>
                </div>)}
            <div className={styles.buttonsContainer}>
                <Button href={`/radio/mediaplans/${id}`} className={styles.hideDesktop}>
                    Отменить
                </Button>
                <Button onClick={onSave} className={styles.saveButton}>
                    Применить
                </Button>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaplanEditScene);
