import React, {useEffect, useState} from "react";
import styles from "./PlaylistEdit.module.scss";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Search from "../../../components/Search";
import Checkbox from "../../../components/common/Checkbox";
import ObjectCard from "../../../components/Cards/ObjectEditPlaylistCard";
import CardCheckbox from "../../../components/Cards/CardCheckbox";
import Button from "../../../components/common/Button";
import classNames from 'class-names';
import ListContainer from "../../../components/ListContainer";
import { showModal } from "../../../redux/actions/ui/modal/modal";
import { connect, useDispatch, useSelector } from "react-redux";
import { useSetCurrentSection } from "../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../constants/pageTypes";
import { useRadioObjects } from "../../../hooks/radio/useRadioObjects";
import Spinner from "../../../components/Spinner";
import {
    loadObjectPlaylists,
    sendObjectPlaylist,
    removeObjectPlaylist,
    loadCurrentPlaylist
} from "../../../redux/modules/background/background-actions";
import Pagination from "../../../components/Pagination";
import { objectPlaylists, playlist } from "../../../redux/modules/background/background-selectors";
import { radioDataSelector } from "../../../redux/modules/radio/radio-selectors";

const breadcrumbsRoutes = (id, namePlaylist, isRAO) => [
    {
        name: "Фон",
        href: "/background",
    },
    {
        name: isRAO ? "РАО" : "Плейлисты" ,
        href: "/background/playlists",
    },
    {
        name: `${namePlaylist}`,
        href: `/background/playlist/${id}`,
    },
    {
        name: "Редактировать",
        disabled: true,
    }
];

const mapDispatchToProps = (dispatch) => ({
    showModal: (data) => dispatch(showModal(data)),
});

const PlaylistEdit = ({ showModal }) => {
    const location = useLocation();
    useSetCurrentSection(location?.state?.RAO ? PAGE_SUBSECTIONS.RAO : PAGE_SUBSECTIONS.PLAYLISTS);
    const [searchType, setSearchType] = useState('name');
    const [itemsSelected, setItemsSelected] = useState([]);
    const [playlistsData, setPlaylistsData] = useState([]);
    const currentPlaylist = useSelector(playlist);
    const playlists = useSelector(objectPlaylists);
    const history = useHistory();
    const dispatch = useDispatch();
    let { id: currentId } = useParams();

    console.log(location?.state, {currentPlaylist})

    useEffect(() => {
        if (currentPlaylist?.objIds) {
            setItemsSelected(currentPlaylist?.objIds);
            currentPlaylist.objIds.map(id =>
                dispatch(loadObjectPlaylists(id, { usedByDefault: true })))
        }
    }, [currentPlaylist, dispatch]);

    useEffect(() => {
        if (playlists) {
            setPlaylistsData(prev => [...prev, playlists?.[0]])
        }
    }, [playlists])

    const onToggle = (id) => {
        setItemsSelected(prev => (
            !!prev?.find(item => item === id) ?
                prev.filter(item => item !== id)
                : [...prev, id]
        ));
    }

    const onSaveObjects = () => {
        const params = {
            playlistId: currentId,
            usedByDefault: true,
        };

        itemsSelected.forEach(id => {
            if (!currentPlaylist.objIds.find((item) => item === id)) {
                dispatch(sendObjectPlaylist(id, params));
            }
        })

        currentPlaylist.objIds.forEach((id) => {
            if (!itemsSelected.find((item) => id === item)) {
                const bundleId = playlistsData.find(item => item.objectId === id)?.id;
                !!bundleId && dispatch(removeObjectPlaylist(id, bundleId));
            }
        })
    }

    const onSubmit = async () => {
        await onSaveObjects();
        await setTimeout(() => {
            dispatch(loadCurrentPlaylist(currentId))
        }, 1000);
        await history.goBack();
    };

    const [data, isLoaded, setPage, setQuery, onSearch] = useRadioObjects();

    if (!data) {
        return (
            <div className={styles.spinnerContainer}>
                <Spinner/>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes(currentId, location?.state?.name, location?.state?.RAO)}/>
            <div className={styles.searchType}>
                <Checkbox name='name'
                          type='card'
                          checked={searchType === 'name'}
                          onChange={() => setSearchType('name')}>
                    По названию
                </Checkbox>
                <Checkbox name='address'
                          type='card'
                          checked={searchType === 'address'}
                          onChange={() => setSearchType('address')}>
                    По адресу
                </Checkbox>
            </div>
            <div className={styles.controlsContainer}>
                <Search showFilter={false}
                        onChange={({ target }) => setQuery(target.value)}
                        onSearch={onSearch}
                        containerClassName={styles.searchContainer}
                        actions={
                            <div className={classNames(styles.buttonsContainer, styles.hideMobile)}>
                                <Button onClick={() => history.goBack()}>
                                    Отменить
                                </Button>
                                <Button onClick={() => {
                                    showModal({
                                        title: "",
                                        text: "Плейлист установлен в качестве плейлиста по умолчанию на выбранных объектах",
                                        submitText: "Хорошо",
                                        cancelText: "Отмена",
                                        onSubmit: () => {
                                            onSubmit();
                                        }
                                    })}}>
                                    Выбрать
                                </Button>
                            </div>
                        }/>
            </div>
            <div className={classNames(styles.buttonsContainer, styles.hideDesktop)}>
                <Button onClick={() => history.goBack()}>
                    Отменить
                </Button>
                <Button
                    onClick={() => {
                        showModal({
                            title: " ",
                            text: "Плейлист установлен в качестве плейлиста по умолчанию на выбранных объектах",
                            submitText: "Хорошо",
                            cancelText: "Отмена",
                            onSubmit: () => {
                                onSubmit();
                            }
                        })}}>
                    Выбрать
                </Button>
            </div>
            <ListContainer className={styles.listContainer} noLoader>
                {isLoaded && data.list.map((item, index) => (
                    <ObjectCard
                        data={item}
                        index={index}
                        onClick={() => onToggle(item.id)}
                        key={index}
                        checkbox={
                            <CardCheckbox
                                onChange={() => onToggle(item.id)}
                                value={item.id}
                                checked={!!itemsSelected?.find(
                                    (value) => item.id === value,
                                )}
                            />
                        }
                    />
                ))}
            </ListContainer>
            {isLoaded && <Pagination
                entityName={'Объектов'}
                totalPages={data.totalPages}
                count={data.totalEntities}
                resultsPerPage={data.pageSize}
                currentPage={data.pageNumber}
                setPage={setPage}
            />}
        </div>
    );
}

export default connect(undefined, mapDispatchToProps)(PlaylistEdit);
