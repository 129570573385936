import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import styles from "./PlaylistItem.module.scss";
import playlistChangeIcon from "../../img/icons/change-playlist-icon.svg";
import playlistDeleteIcon from "../../img/icons/Delete-playlist-icon.svg";

const PlaylistItem = ({title, image, id, onDelete, objectId, dateTimeFrom, type, bundleId}) => {
    return (
        <div className={styles.playlist}>
            <div className={styles.titleContainer}>
                <div className={styles.image}>
                    <img src={image}  />
                </div>
                <Link className={styles.link} to={`/background/playlist/${id}`}>
                    <p>{title}</p>
                </Link>
                {type === 'RAO'  && (
                    <div className={styles.containerType}>
                        <span className={styles.type}>РАО</span>
                    </div>
                )}
            </div>
            <div className={styles.buttonsContainer}>
                {/*<Link className={styles.link}*/}
                {/*      to={{*/}
                {/*          pathname: `/radio/object/background/${objectId}/playlists/calendar/${id}`,*/}
                {/*          state: { date: dateTimeFrom }*/}
                {/*      }}*/}
                {/*>*/}
                {/*    <button className={styles.button}>*/}
                {/*        <img alt="playlist-change"*/}
                {/*            className={styles.icon}*/}
                {/*            src={playlistChangeIcon} />*/}
                {/*    </button>*/}
                {/*</Link>*/}
                <button className={styles.button} onClick={() => onDelete(objectId, bundleId)}>
                    <img alt="playlist-delete"
                        className={styles.icon}
                        src={playlistDeleteIcon} />
                </button>
            </div>
        </div>
    );
};

PlaylistItem.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.number,
    onDelete: PropTypes.func,
};

export default PlaylistItem;
