import React from 'react';
import styles from './OrdersListScene.module.scss';
import {connect, useDispatch, useSelector} from "react-redux";
import Pagination from "../../../components/Pagination";
import Search from "../../../components/StudioSearch";
import recordIcon from "../../../img/icons/record-icon.svg";
import { PAGE_SUBSECTIONS, PAGE_TYPE } from "../../../constants/pageTypes";
import { useSetCurrentSection } from "../../../hooks/useSetCurrentSection";
import RecordActions from "../../../components/Search/actions/RecordActions";
import CardCheckbox from "../../../components/Cards/CardCheckbox";
import { showModal } from "../../../redux/actions/ui/modal/modal";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ListContainer from "../../../components/ListContainer";
import { useStudioMetadata } from "../../../hooks/useStudioMetadata";
import { useSelectableList } from "../../../hooks/useSelectableList";
import { createStudioDataSelector } from "../../../redux/modules/studio/studio-selectors";
import { STUDIO_ENTITY } from "../../../constants/studioEntities";
import { loadStudioData, orderNewTrack } from "../../../redux/modules/studio/studio-actions";
import Spinner from "../../../components/Spinner";
import OrderRecordCard  from "../../../components/Cards/OrderTrackCard/index";

const breadcrumbsRoutes = [{
    name: 'Студия звукозаписи',
    href: '#'
}, {
    name: 'Заказы',
    href: '/studio/orders'
}];

export const OrdersListScene = () => {
    useSetCurrentSection(PAGE_SUBSECTIONS.ORDERS);
    const [metadataLoaded] = useStudioMetadata();
    const data = useSelector(createStudioDataSelector(STUDIO_ENTITY.ORDERS));
    const [onToggle, selectAll, selectedIds, unselectAll, setSelectedIds] = useSelectableList();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(loadStudioData(STUDIO_ENTITY.ORDERS))
    }, [dispatch]);

    const setPage = (page) => {
        dispatch(loadStudioData(STUDIO_ENTITY.ORDERS, page))
    };

    const orderTrack = () => {
        dispatch(orderNewTrack());
    }
    return (
        <div className={styles.container}>
            <Breadcrumbs className={styles.breadcrumbs} routes={breadcrumbsRoutes}/>
            <div className={styles.recordControlsContainer}>
                {metadataLoaded && <Search
                    showSearch={false}
                    entity={STUDIO_ENTITY.ORDERS}
                    actions={<RecordActions
                        orderTrack={orderTrack}
                        pageType={PAGE_TYPE.ORDERS}
                    />}
                />}
            </div>
            {data?.isLoading ? (
                    <div className={styles.spinnerContainer}>
                        <Spinner/>
                    </div>
                ) : (
                    <ListContainer noLoader className={styles.listContainer}>
                        {data?.list?.map((record, index) => (
                            <OrderRecordCard
                                isShowPlayer={record.status === 'NEGOTIATION'}
                                record={record}
                                showStatus
                                entity={STUDIO_ENTITY.ORDERS}
                                icon={recordIcon}
                                key={index}
                            />))}
                    </ListContainer>
                )}
            {!data?.isLoading && data?.totalPages &&
                <Pagination
                    entityName='Роликов'
                    totalPages={data?.totalPages}
                    count={data?.totalRecords}
                    resultsPerPage={data?.pageSize}
                    currentPage={data?.pageNumber}
                    setPage={setPage}
                    setSelected={setSelectedIds}
                    selectedIds={selectedIds}
                    items={data?.list}
                />}
        </div>
    );
};

export default OrdersListScene;
