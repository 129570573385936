import React, {useEffect, useState} from 'react';
import styles from './MediaplanScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import classNames from 'class-names';
import {Link, useHistory} from "react-router-dom";
import Popup from "../../../../components/Popup";
import Spinner from "../../../../components/Spinner";
import { getOptionalValue } from "../../../../utils/getOptionalValue";
import { useRadioMediaplan } from "../../../../hooks/radio/useRadioMediaplan";
import {deleteRadioMediaplan} from "../../../../api/radio-api";
import {showModal} from "../../../../redux/actions/ui/modal/modal";
import {useDispatch} from "react-redux";

const breadcrumbsRoutes = (id) => [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Эфир',
    href: '/radio/broadcast'
}, {
    name: 'Страница медиаплана',
    href: `/radio/mediaplans/${id}`
}];

const MediaplanScene = ({
    match,
}) => {
    const { id } = match.params;
    const history = useHistory();
    const dispatch = useDispatch();
    const [entityData, isLoaded] = useRadioMediaplan(id);
    const [showPopup, setShowPopup] = useState(false);

    const mediaplanInfo = entityData?.data;

    const [trackLength, setTrackLength] = useState(0);

    useEffect(() => {
        setTrackLength(getOptionalValue(mediaplanInfo, 'tracks.length'))
    }, [mediaplanInfo])

    const onDelete = async () => {
        try {
            await deleteRadioMediaplan(mediaplanInfo.id);
            history.push('/radio/broadcast');
        } catch (e) {
            const { response } = e;
            dispatch(showModal({
                title: 'Ошибка',
                text: response.data.errorMessage,
                cancelText: 'Закрыть',
                submitText: null,
            }))
        }
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes(id)}/>
            {isLoaded ? (
                <div className={styles.pageContent}>
                    <div className={styles.flexContainer}>
                        <div className={styles.verticalCenter}>
                            <div className={styles.icon}/>
                            <div className={styles.id}>
                                ID {mediaplanInfo.id}
                            </div>
                        </div>
                        <div className={styles.menuButton} onClick={() => setShowPopup(!showPopup)}>
                            {showPopup &&
                            <Popup closePopup={(e) => {
                                e && e.preventDefault();
                                setShowPopup(false)
                            }} className={styles.popup}>
                                <Link to={`/radio/mediaplans/${mediaplanInfo.id}/edit`} className="popup-menu-item">
                                    Редактировать медиаплан
                                </Link>
                                {/*<div className="popup-menu-item" onClick={onDelete}>*/}
                                {/*    Удалить медиаплан*/}
                                {/*</div>*/}
                            </Popup>}
                        </div>
                    </div>
                    <div className={styles.titleContainer}>
                        <div className={styles.title}>
                            {mediaplanInfo.title}
                        </div>
                        <div className={styles.buttonsContainer}>
                            <Link to={`/radio/mediaplans/${mediaplanInfo.id}/edit`} className={styles.button}>
                                Редактировать
                            </Link>
                            {/*<div className={styles.button} onClick={onDelete}>*/}
                            {/*    Удалить*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className={styles.responsivePart}>
                        <div className={styles.infoContainer}>
                            <div className={classNames(styles.dateContainer, styles.hideDesktop)}>
                                <div className={styles.standardText}>
                                    Начало вещания - Окончание вещания
                                </div>
                                <div className={styles.date}>
                                    {new Date(mediaplanInfo.dateFrom).toLocaleDateString()} - {new Date(mediaplanInfo.dateTo).toLocaleDateString()}
                                </div>
                            </div>
                            <div className={styles.tabletColumnsContainer}>
                                <div className={styles.tabletColumn}>
                                    <div className={styles.flexContainer}>
                                        <div className={styles.standardText}>
                                            Интенсивность медиаплана
                                        </div>
                                        <div className={styles.loadStatus}>
                                            {mediaplanInfo.intensity}
                                        </div>
                                    </div>
                                    <div className={classNames(styles.buttonsContainer, styles.tabletButtons)}>
                                        <Link to={`/radio/mediaplans/${mediaplanInfo.id}/edit`} className={styles.button}>
                                            Редактировать
                                        </Link>
                                        {/*<div className={styles.button} onClick={onDelete}>*/}
                                        {/*    Удалить*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className={styles.tabletColumn}>
                                    <div className={styles.mediaplanContentContainer}>
                                        <div className={styles.mediaplanContentTitle}>
                                            Содержание медиаплана
                                        </div>
                                        <Link to={`/radio/mediaplans/${id}/objects`} className={styles.mediaplanContentItem}>
                                            <div className={styles.objectsIcon}/>
                                            Объекты
                                            <div className={styles.mediaplanContentNumber}>
                                                {getOptionalValue(mediaplanInfo, 'objects.length')}
                                            </div>
                                        </Link>
                                        <Link to={`/radio/mediaplans/${id}/records`} className={styles.mediaplanContentItem}>
                                            <div className={styles.recordIcon}/>
                                            Ролики
                                            <div className={styles.mediaplanContentNumber}>
                                                {trackLength}
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/*<Link to={'/radio/mediaplan/timeline'}*/}
                            {/*      className={classNames(styles.button, styles.hideMobile, styles.timelineButton)}>*/}
                            {/*    Таймлайн*/}
                            {/*</Link>*/}
                        </div>
                        <div className={styles.descContainer}>
                            <div className={classNames(styles.dateContainer, styles.hideMobile)}>
                                <div className={styles.standardText}>
                                    Начало вещания - Окончание вещания
                                </div>
                                <div className={styles.date}>
                                    {new Date(mediaplanInfo.dateFrom).toLocaleDateString()} - {new Date(mediaplanInfo.dateTo).toLocaleDateString()}
                                </div>
                            </div>
                            <div className={classNames(styles.standardText, styles.descTitle)}>
                                Описание
                            </div>
                            <div className={styles.standardText}>
                                {mediaplanInfo.description}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.spinnerContainer}>
                    <Spinner/>
                </div>
            )}
        </div>
    );
};

export default MediaplanScene;
