import {isDevelopment} from "../utils/isProduction";

export const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;
export const RADIO_URL = process.env.REACT_APP_RADIO_URL;
export const PROFILE_URL = process.env.REACT_APP_PROFILE_URL;
export const STUDIO_URL = process.env.REACT_APP_STUDIO_URL;
export const HOST_URL = process.env.REACT_APP_HOST_URL;
//export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
//export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

export const REDIRECT_TO = isDevelopment() ? `http://localhost:3000/auth`
    : HOST_URL;
