import { isDevelopment } from "../utils/isProduction";
import { api } from "../App";
import axios from "axios";
import {AUTH_BASE_URL, REDIRECT_TO} from "./config";

const AUTHORIZE_URL = `${AUTH_BASE_URL}/oauth/authorize`;
const TOKEN_URL = `${AUTH_BASE_URL}/oauth/token`;
const LOGOUT_URL = `${AUTH_BASE_URL}/oauth/logout`;

export const CLIENT_ID = 'yradio_lk';
export const CLIENT_SECRET = 'WueCivai7Oquaichaiwo';
export const GRANT_TYPE_CODE = 'authorization_code';
export const GRANT_TYPE_TOKEN = 'refresh_token';

export const redirectToAuth = (uniqueCode) => {
    const urlParams = new URLSearchParams();

    urlParams.append('state', uniqueCode);
    urlParams.append('response_type', 'code');
    urlParams.append('client_id', CLIENT_ID);
    urlParams.append('redirect_uri', REDIRECT_TO);
    window.location.assign(`${AUTHORIZE_URL}?${urlParams.toString()}`);
}

export const loadInitialTokens = async (authCode) => {
    const formData = new FormData();
    formData.set('code', authCode);
    formData.set('client_id', CLIENT_ID);
    formData.set('redirect_uri', REDIRECT_TO);
    formData.set('grant_type', GRANT_TYPE_CODE);

    const authCredentials = btoa(`${CLIENT_ID}:${CLIENT_SECRET}`);

    return api.post(TOKEN_URL, formData, {
        headers: {
            'Authorization': `Basic ${authCredentials}`,
        },
    })
};

export const logOutRequest = async () => {
    return axios.post(LOGOUT_URL);
}
