import {
    deleteObjectPlaylist,
    fetchBackgroundPlaylists,
    fetchCurrentPlaylist,
    fetchObjectPlaylists,
    postObjectPlaylist,
    fetchPurchasedPlaylists,
    fetchTimelinePlaylists,
} from '../../../api/background-api';
import { showModal } from "../../actions/ui/modal/modal";

export const SET_BACKGROUND_PLAYLISTS = 'SET_BACKGROUND_PLAYLISTS';
export const SET_BACKGROUND_RAO = 'SET_BACKGROUND_RAO';
export const SET_CURRENT_PLAYLIST = 'SET_CURRENT_PLAYLIST';
export const SET_OBJECT_PLAYLISTS = 'SET_OBJECT_PLAYLISTS';
export const SET_TIMELINE_PLAYLISTS = 'SET_TIMELINE_PLAYLISTS';
export const SET_PURCHASED_PLAYLISTS = 'SET_PURCHASED_PLAYLISTS';

const setBackgroundPlaylists = (backgroundPlaylists) => ({
    type: SET_BACKGROUND_PLAYLISTS,
    payload: { backgroundPlaylists },
});

const setBackgroundRAO = (backgroundRAO) => ({
    type: SET_BACKGROUND_RAO,
    payload: { backgroundRAO },
});

const setCurrentPlaylist = (playlist) => ({
    type: SET_CURRENT_PLAYLIST,
    payload: { playlist },
});

const setObjectPlaylists = (objectPlaylists) => ({
    type: SET_OBJECT_PLAYLISTS,
    payload: { objectPlaylists },
});

const setTimelinePlaylists = (timelinePlaylists) => ({
    type: SET_TIMELINE_PLAYLISTS,
    payload: { timelinePlaylists },
});

const setPurchasedPlaylists = (purchasedPlaylists) => ({
    type: SET_PURCHASED_PLAYLISTS,
    payload: { purchasedPlaylists },
});

const handleBackgroundError = (error) => (dispatch) => {
    if (error.response && error.response.data) {
        const responseData = error.response.data;
        if (responseData.errorMessage) {
            dispatch(showModal({
                title: 'Ошибка',
                text: responseData.errorMessage,
                cancelText: 'Закрыть',
                submitText: 'Назад',
                onSubmit: () => window.history.back(),
            }))
        }
    } else {
        console.error(error);
    }
}

export const loadBackgroundPlaylists = (options) => async (dispatch) => {
    try {
        const params = {
            _page: 0,
            _pageSize: 10,
            ...options,
        };
        const backgroundPlaylists = await fetchBackgroundPlaylists(params);

        if (options?.type === 'RAO') {
            dispatch(setBackgroundRAO(backgroundPlaylists.data));
        } else {
            dispatch(setBackgroundPlaylists(backgroundPlaylists.data));
        }

    } catch (e) {
        dispatch(handleBackgroundError(e));
    }
};

export const loadCurrentPlaylist = (id) => async (dispatch) => {
    try {
        const params = {
            id: id,
        };

        const { data } = await fetchCurrentPlaylist(id, params);
        dispatch(setCurrentPlaylist(data));
    } catch (e) {
        dispatch(handleBackgroundError(e));
    }
};

export const loadObjectPlaylists = (objectId, params) => async (dispatch) => {
    try {
        const { data } = await fetchObjectPlaylists(objectId, params);

        dispatch(setObjectPlaylists(data?.map((item) => ({ ...item, objectId }))));
    } catch (e) {
        dispatch(handleBackgroundError(e));
    }
};

export const loadTimelinePlaylists = (objectId, params) => async (dispatch) => {
    try {
        const { data } = await fetchTimelinePlaylists(objectId, params);
        dispatch(setTimelinePlaylists(data));

    } catch (e) {
        dispatch(handleBackgroundError(e));
    }
};

export const loadPurchasedPlaylists = (objectId, params) => async (dispatch) => {
    try {
        const { data } = await fetchPurchasedPlaylists(objectId, params);
        dispatch(setPurchasedPlaylists(data));
    } catch (e) {
        dispatch(handleBackgroundError(e));
    }
};

export const sendObjectPlaylist = (objectId, params) => async (dispatch) => {
    try {
        await postObjectPlaylist(objectId, params);
    } catch (e) {
        dispatch(handleBackgroundError(e));
        throw e;
    }
};

export const removeObjectPlaylist = (objectId, id) => async (dispatch) => {
    try {
        await deleteObjectPlaylist(objectId, id);
    } catch (e) {
        dispatch(handleBackgroundError(e));
    }
};
