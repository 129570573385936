import React, { useEffect, useState } from 'react';
import styles from './ObjectMaintenanceAddScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import ItemListTitle from "../../../../components/ItemListTitle";
import objectIcon from "../../../../img/icons/object-dark-grey-icon.svg";
import RecordStatus from "../../../../components/Cards/RecordCard/RecordStatus";
import { RECORD_ONLINE_STATUS, RECORD_STATUS } from "../../../../constants/recordTypes";
import Input from "../../../../components/common/Input";
import Button from "../../../../components/common/Button";
import { useSetCurrentSection } from "../../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../../constants/pageTypes";
import { connect, useDispatch, useSelector } from "react-redux";
import { profileSelectorMaintenanceReasons } from "../../../../redux/modules/profile/profile-selectors";
import {
    loadMaintenanceReasons,
    sendProfileMaintenance,
} from "../../../../redux/modules/profile/profile-actions";
import Select from "../../../../components/common/Select";
import Spinner from "../../../../components/Spinner";
import {showModal} from "../../../../redux/actions/ui/modal/modal";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useRadioObject} from "../../../../hooks/radio/useRadioObject";

const mapDispatchToProps = (dispatch) => ({
    showModal: (data) => dispatch(showModal(data)),
});

const ObjectMaintenanceAddScene = ({ showModal }) => {
    const maintenanceReasons = useSelector(profileSelectorMaintenanceReasons);
    const [reason, setReason] = useState();
    const { id: objectId } = useParams();
    const [comment, setComment] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const isTechSupport = location.state?.isTechSupport;
    useSetCurrentSection(isTechSupport ? PAGE_SUBSECTIONS.TECH_SUPPORT : PAGE_SUBSECTIONS.OBJECTS);
    const [entityData, isLoaded] = useRadioObject(objectId);

    const breadcrumbsRoutes = !isTechSupport ? [
        {
            name: 'Корпоративное радио',
            href: '#'
        }, {
            name: 'Объекты',
            href: '/radio/objects'
        }, {
            name: 'Страница объекта',
            href: `/radio/objects/${entityData?.id}`
        }, {
            name: entityData?.data?.title,
            href: '/radio/object/maintenance'
        }, {
            name: 'Создание заявки ТО',
            href: '/radio/object/maintenance/add'
        }
    ] : [
        {
            name: 'Тех. сопровождение',
            href: '/profile/tech-support'
        }, {
            name: entityData?.data?.title,
            href: '/radio/object/maintenance'
        }, {
            name: 'Создание заявки ТО',
            href: '/radio/object/maintenance/add'
        }
    ];

    useEffect(() => {
        dispatch(loadMaintenanceReasons());
    }, [dispatch]);

    useEffect(() => {
        setReason(maintenanceReasons?.[0].id)
    },[maintenanceReasons]);

    const onSubmit = () => {
        history.push(`/radio/object/${entityData?.id}/maintenance`)
    }

    if (!maintenanceReasons || !entityData?.data?.title) {
        return (
            <div className={styles.spinnerContainer}>
                <Spinner/>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes} className={styles.breadcrumbs}/>
            <ItemListTitle icon={objectIcon}
                           className={styles.pageTitle}
                           title={entityData?.data.title}>
                ID 51464 <RecordStatus onlineStatus={RECORD_ONLINE_STATUS.ONLINE}
                                       status={RECORD_STATUS.READY} />
            </ItemListTitle>
            <div className={styles.form}>
                <Select options={maintenanceReasons}
                        className={styles.selectWrapper}
                        onChange={(e) => {
                            const option = maintenanceReasons.find(item => item.name === e.target.value);
                            setReason(option.id);
                        }}
                />
                <Input name='description'
                       type='textarea'
                       label='Комментарий'
                       onChange={(e) => setComment(e.target.value)}
                />
            </div>
            <div className={styles.buttonsContainer}>
                <Button href={'/radio/object/maintenance'} className={styles.hideDesktop}>
                    Отменить
                </Button>
                <Button className={styles.submitButton}
                        onClick={() => {
                            const params = {
                                objId: Number(entityData.id),
                                reasonId: reason,
                                comment,
                            };

                            dispatch(sendProfileMaintenance(params));
                            setComment({});

                            showModal({
                                title: "Cообщение",
                                text: "Отправить заявку?",
                                submitText: "Да",
                                cancelText: "Отмена",
                                onSubmit: () => onSubmit(),
                            })
                        }}
                >
                    Отправить заявку
                </Button>
            </div>
        </div>
    );
};

export default connect(undefined, mapDispatchToProps)(ObjectMaintenanceAddScene);
