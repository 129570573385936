import React, { useCallback, useEffect, useState } from 'react';
import styles from './BroadcastPauseScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import objectIcon from "../../../../img/icons/object-dark-grey-icon.svg";
import RecordStatus from "../../../../components/Cards/RecordCard/RecordStatus";
import { RECORD_ONLINE_STATUS, RECORD_STATUS } from "../../../../constants/recordTypes";
import ItemListTitle from "../../../../components/ItemListTitle";
import TimePicker from "../../../../components/TimePicker";
import Input from "../../../../components/common/Input";
import Button from "../../../../components/common/Button";
import classNames from 'class-names';
import BroadcastPauseCard from "../../../../components/Cards/BroadcastPauseCard";
import { useParams } from "react-router-dom";
import {
    addObjectBroadcastPause, deleteObjectBroadcastPause,
    loadObjectBroadcastPauses,
    objectPausesSelector
} from "../../../../redux/modules/radio/slices/object-pauses-slice";
import { useDispatch, useSelector } from "react-redux";
import { LoadingWrapper } from "../../../../components/LoadingWrapper";
import moment from "moment";
import { loadRadioData } from "../../../../redux/modules/radio/radio-actions";
import { RADIO_ENTITY } from "../../../../constants/radioEntities";
import { radioDataSelector } from "../../../../redux/modules/radio/radio-selectors";
import { getOptionalValue } from "../../../../utils/getOptionalValue";

const generateBreadcrumbs = (id) =>
    [{
        name: 'Корпоративное радио',
        href: '#'
    }, {
        name: 'Объекты',
        href: '/radio/objects'
    }, {
        name: 'Страница объекта',
        href: `/radio/objects/${id}`
    }, {
        name: 'Приостановка эфира',
        href: `/radio/objects/${id}/pause`
    }];

const initialFormState = {
    momentFrom: moment().toISOString(),
    momentTo: moment().toISOString(),
    title: ''
};

const BroadcastPauseScene = () => {
    const { id: objectId } = useParams();
    const [formVisible, setFormVisible] = useState(false);
    const [formKey, setFormKey] = useState(0);
    const dispatch = useDispatch();
    const pausesState = useSelector(objectPausesSelector);
    const [formData, setFormData] = useState(initialFormState);
    const objectState = useSelector(radioDataSelector);

    useEffect(() => {
        dispatch(loadRadioData(RADIO_ENTITY.OBJECT, objectId))
    }, [dispatch, objectId]);

    const objectInfo = objectState && objectState.data;

    const handleDatePicker = (event) => {
        const { name, value } = event.target;
        const newValue = moment(value).set({
            hours: moment(formData[name]).hours(),
            minutes: moment(formData[name]).minutes()
        }).toISOString();

        setFormData({...formData, [name]: newValue})
    }

    const handleTimePicker = useCallback(({ name, hours, minutes }) => {
        setFormData(oldData => ({
            ...oldData,
            [name]: moment(oldData[name]).set({ hour: hours, minute: minutes, milliseconds: 0 }).toISOString()
        }))
    }, []);

    useEffect(() => {
        dispatch(loadObjectBroadcastPauses({ objectId }))
    }, [dispatch, objectId])

    const onSubmit = async () => {
        const pauseData = {
            ...formData,
            momentFrom: moment(formData.momentFrom).format('YYYY-MM-DDTHH:mm:ss'),
            momentTo: moment(formData.momentTo).format('YYYY-MM-DDTHH:mm:ss'),
        }
        await dispatch(addObjectBroadcastPause({objectId, pauseData}));
        setFormData(initialFormState);
        setFormKey(formKey + 1);
        dispatch(loadObjectBroadcastPauses({ objectId }))
    }

    const onDelete = async (pauseId) => {
        await dispatch(deleteObjectBroadcastPause({objectId, pauseId}))
        dispatch(loadObjectBroadcastPauses({ objectId }))
    }

    console.log({ objectId });

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={generateBreadcrumbs(objectId)}/>
            <ItemListTitle icon={objectIcon}
                           title={`${getOptionalValue(objectInfo, 'title')} ${getOptionalValue(objectInfo, 'address')}`}>
                ID {objectId} <RecordStatus onlineStatus={objectInfo?.online ? RECORD_ONLINE_STATUS.ONLINE : RECORD_ONLINE_STATUS.OFFLINE}
                                       status={RECORD_STATUS.READY}/>
            </ItemListTitle>
            <div className={styles.pageContainer}>
                <div className={styles.controlsColumn}>
                    <div className={styles.hintText}>
                        <div className={styles.hintTextTitle}>
                            Приостановка эфира
                        </div>
                        Постановка задачи на приостановку эфира должна быть минимум за час до самого момента
                        приостановки
                        эфира.
                    </div>
                    <div className={styles.formControls}>
                        Дата и время приостановки
                        <Button onClick={() => setFormVisible(!formVisible)} className={styles.formButton}>
                            {formVisible ? "Скрыть" : "Добавить"}
                        </Button>
                    </div>
                    <div className={classNames(styles.broadcastPauseForm, { [styles.visible]: formVisible })} key={formKey}>
                        <div className={styles.dateInputsContainer}>
                            <Input
                                   value={formData.title}
                                   onChange={({ target }) => setFormData({...formData, title: target.value})}
                                   className={styles.dateInput}
                                   label="Причина приостановки"
                            />

                            <Input name="momentFrom"
                                   value={moment(formData.momentFrom).format('YYYY-MM-DD')}
                                   onChange={handleDatePicker}
                                   className={styles.dateInput}
                                   label={'Начало приостановки'}
                                   type={'date'} />
                            <TimePicker
                                date={formData.momentFrom}
                                onChange={handleTimePicker}
                                name="momentFrom"
                                className={styles.timePicker}
                            />
                        </div>
                        <div className={styles.dateInputsContainer}>
                            <Input name="momentTo"
                                   value={moment(formData.momentTo).format('YYYY-MM-DD')}
                                   className={styles.dateInput}
                                   onChange={handleDatePicker}
                                   label={'Окончание приостановки'}
                                   type={'date'}/>
                            <TimePicker
                                date={formData.momentTo}
                                onChange={handleTimePicker}
                                name="momentTo"
                                className={styles.timePicker}
                            />
                        </div>
                        <div className={styles.buttonContainer}>
                            <Button onClick={onSubmit}>
                                Применить
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={styles.listContainer}>
                    <LoadingWrapper isLoading={pausesState.isListLoading}>
                        {pausesState.list.map(pauseItem => (
                            <BroadcastPauseCard {...pauseItem} onDelete={onDelete} key={pauseItem.id}/>
                        ))}
                    </LoadingWrapper>
                </div>
            </div>
        </div>
    );
};

export default BroadcastPauseScene;
