import { useDispatch, useSelector } from "react-redux";
import { radioDataSelector } from "../../redux/modules/radio/radio-selectors";
import { loadRadioData } from "../../redux/modules/radio/radio-actions";
import { RADIO_ENTITY } from "../../constants/radioEntities";
import React from "react";
import { isRadioPageLoaded } from "../../redux/modules/radio/radio-helpers";
import { useRadioMetadata } from "./useRadioMetadata";

export const useRadioObject = (id) => {
    const dispatch = useDispatch();
    useRadioMetadata();

    const entityData = useSelector(radioDataSelector);
    const loadObjectData = (id) => dispatch(loadRadioData(RADIO_ENTITY.OBJECT, id));

    React.useEffect(() => {
        loadObjectData(id)
    }, [id]);

    const isLoaded = isRadioPageLoaded(RADIO_ENTITY.OBJECT, entityData);

    return [entityData, isLoaded];
}
