import React, { useState } from 'react';
import GenericCard from "../GenericCard";
import styles from "./PriorityAdCard.module.scss";
import Popup from "../../Popup";
import {getOptionalValue} from "../../../utils/getOptionalValue";
import moment from "moment";
import {STUDIO_ENTITY} from "../../../constants/studioEntities";
import AudioPlayer from "../../AudioPlayer";

const PriorityAdCard = ({data, onDelete, onEdit}) => {
    const [showPopup, setShowPopup] = useState(false);

    const onToggleFavorite = () => {
        onEdit({
            ...data,
            favorite: !data.favorite
        });
    }

    const handleDelete = () => {
        onDelete(data.id);
    }

    return (
        <GenericCard className={styles.card} headClassName={styles.head}
                     headContent={<>
                         <div className={styles.headInfo}>
                             {data.favorite && <div className={styles.starIcon}/>}
                             <div className={styles.icon}/>
                             ID {getOptionalValue(data, 'id')}
                         </div>
                         <div className={styles.menuButton} onClick={() => setShowPopup(!showPopup)}>
                             {showPopup &&
                             <Popup closePopup={(e) => {
                                 e.preventDefault();
                                 setShowPopup(false)
                             }} className={styles.cardPopup}>
                                 <div className="popup-menu-item" onClick={onToggleFavorite}>
                                     Переключить избранность
                                 </div>
                                 <div className="popup-menu-item" onClick={handleDelete}>
                                     Удалить объявление
                                 </div>
                             </Popup>}
                         </div>
                     </>}>
            <div className={styles.contentContainer}>
                <div className={styles.textContainer}>
                    <div className={styles.title}>
                        {getOptionalValue(data, 'title')}
                    </div>
                    <div className={styles.infoBlock}>
                        Дата создания
                        <div className={styles.infoValue}>
                            {moment(data.dateCreate).format('DD.MM.YYYY')}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.playerContainer}>
                <AudioPlayer
                    trackId={data.trackId}
                    entityName={STUDIO_ENTITY.RECORDS}
                />
            </div>
        </GenericCard>
    );
};

export default PriorityAdCard;
