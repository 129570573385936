import React from 'react';
import styles from './ObjectSceneInfo.module.scss';
import classNames from 'class-names';
import ObjectSceneDropdown from "../ObjectSceneDropdown";
import { Link } from "react-router-dom";
import Button from "../../common/Button";
import tariff from '../../../img/icons/rate.svg';
import eqiupmentRent from '../../../img/icons/Equipment_rent.svg';
import music from '../../../img/icons/music.svg';
import internet from '../../../img/icons/Internet.svg';
import RaoVois from '../../../img/icons/RAO+VOIS.svg';
import documentLoad from '../../../img/icons/document_load_object.svg'
import moment from "moment";

const SHOW_TARIFF = false;

const ObjectSceneInfo = ({ objectInfo }) => {
    if (!objectInfo) {
        return null;
    }

    const information = objectInfo.info[0];

    return (
        <div className={styles.container}>
            {objectInfo.startPlayFromTime && (
            <div className={styles.infoBlock}>
                <div className={styles.name}>
                    Информация о объекте
                </div>
                <div className={styles.value}>
                    {Date(objectInfo.startPlayFromTime) > new Date() ?
                        `Эфир включен с ${moment(objectInfo.startPlayFromTime)}`
                        : `Эфир выключен до ${moment(objectInfo.startPlayFromTime).format('DD-MM-YYYY HH:mm')}`}
                </div>
            </div>)}
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Состояние транзакции
                </div>
                <div className={styles.value}>
                    {information.transaction}
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Последняя транзакция
                </div>
                <div className={styles.value}>
                    {moment(information.transactionDate).format('DD-MM-YYYY HH:mm')}
                </div>
            </div>
            <div className={styles.sectionTitle}>
                Общие сведения
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Регион
                </div>
                <div className={styles.value}>
                    {information.region}
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Проходимость объекта
                </div>
                <div className={styles.value}>
                    {information.passability}
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Категория проходимости
                </div>
                <div className={styles.value}>
                    {information.category}
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Широта объекта
                </div>
                <div className={styles.value}>
                    {information.latlong.split(':')?.[0]}' с. ш.
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Долгота объекта
                </div>
                <div className={styles.value}>
                    {information.latlong.split(':')?.[1]}' в. д.
                </div>
            </div>
            <div className={styles.sectionTitle}/>
            <div className={styles.infoBlock}>
                <div className={styles.name}>
                    ФИО ответственного
                </div>
                <div className={styles.value}>
                    {information.responsiblePerson}
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Дни работы
                </div>
                <div className={styles.value}>
                    {information.openingHours[0].day}
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Часы работы
                </div>
                <div className={styles.value}>
                    {information.openingHours[0].hours}
                </div>
            </div>
            <ObjectSceneDropdown buttonContent={(open) =>
                <>
                    <div className={styles.rateIcon}/>
                    {open ? "Скрыть информацию о тарифе" : "Тариф"}
                </>}>
                {SHOW_TARIFF && <>
                    <div className={styles.infoBlock}>
                        <div className={classNames(styles.name, styles.header)}>
                            <img className={styles.tariffImage} src={tariff}/>
                            <p className={styles.tariff}>Тариф 2 (2 990 ₽)</p>
                        </div>
                        <div className={classNames(styles.value, styles.header)}>
                            <p>Краткое описание тарифа при необходимости</p>
                        </div>
                    </div>
                    <div className={classNames(styles.infoBlock, styles.half)}>
                        <div className={styles.name}>
                            <img className={styles.tariffBlockImage} src={eqiupmentRent}/>
                            <p className={styles.tariffBlock}>Аренда оборудования</p>
                        </div>
                        <div className={styles.value}>
                            <p>2 точки (1980 ₽)</p>
                        </div>
                    </div>
                    <div className={classNames(styles.infoBlock, styles.half)}>
                        <div className={styles.name}>
                            <img className={styles.tariffBlockImage} src={internet}/>
                            <p className={styles.tariffBlock}>Интернет</p>
                        </div>
                        <div className={styles.value}>
                            <p>Нет</p>
                        </div>
                    </div>
                    <div className={classNames(styles.infoBlock, styles.half)}>
                        <div className={styles.name}>
                            <img className={styles.tariffBlockImage} src={music}/>
                            <p className={styles.tariffBlock}>Музыкальные пакеты</p>
                        </div>
                        <div className={styles.value}>
                            <p>5 (2 475 ₽)</p>
                        </div>
                    </div>
                    <div className={classNames(styles.infoBlock, styles.half)}>
                        <div className={styles.name}>
                            <img className={styles.tariffBlockImage} src={RaoVois}/>
                            <p className={styles.tariffBlock}>РАО + ВОИС</p>
                        </div>
                        <div className={styles.value}>
                            <p>Да (10 000 ₽)</p>
                        </div>
                    </div>
                </>}
                <div className={styles.infoBlock}>
                    <div className={styles.name}>
                        <div className={styles.documentBlock}>
                            <div className={styles.documentLoad}>
                                <img className={styles.tariffBlockImage} src={documentLoad}/>
                                <div>
                                    {objectInfo.documents.map(document => (
                                        <Link className={styles.tariffDocument} to={document.link}>
                                            {document.title}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                            {/*<div className={styles.payment}><p>Ежемесячный платёж 17445 ₽</p></div>*/}
                        </div>
                    </div>
                    <div className={styles.value}>
                    </div>
                </div>
            </ObjectSceneDropdown>
            <Link to={'/radio/object/maintenance'} className={styles.maintenanceLink}>
                <ObjectSceneDropdown buttonContent={() => <>
                    <div className={styles.maintenanceIcon}/>
                    Техническое обслуживание</>}/>
            </Link>
        </div>
    );
};

export default ObjectSceneInfo;
