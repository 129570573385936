import React, {useCallback} from 'react';
import styles from './MediaplanCreateScene.module.scss';
import { useSetCurrentSection } from "../../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../../constants/pageTypes";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import Input from "../../../../components/common/Input";
import Select from "../../../../components/common/Select";
import Button from "../../../../components/common/Button";
import { radioFormDataSelector } from "../../../../redux/modules/radio/radio-selectors";
import { createRadioMediaplan, setRadioFormData } from "../../../../redux/modules/radio/radio-actions";
import { connect } from "react-redux";
import { RADIO_ENTITY } from "../../../../constants/radioEntities";
import { useHistory } from "react-router-dom";

const breadcrumbsRoutes = [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Эфир',
    href: '/radio/broadcast'
}, {
    name: 'Создание медиаплана',
    href: '/radio/mediaplan/create'
}];

const selectOptions = ['15', '30', '60', '120'];

const mapStateToProps = (state) => ({
    formData: radioFormDataSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    setFormData: (data) => dispatch(setRadioFormData(data)),
    createMediaplan: (data) => dispatch(createRadioMediaplan(data)),
});

const DEFAULT_FORM_DATA = {
    dateFrom: '',
    dateTo: '',
    description: '',
    intensity: '15',
    objects: [],
    title: ''
};

const MediaplanCreateScene = ({
    formData,
    setFormData,
    createMediaplan,
}) => {
    useSetCurrentSection(PAGE_SUBSECTIONS.BROADCAST);
    const history = useHistory();

    React.useEffect(() => {
        if (!formData || formData.entityName !== RADIO_ENTITY.MEDIAPLAN) {
            setFormData({
                entityName: RADIO_ENTITY.MEDIAPLAN,
                data: {...DEFAULT_FORM_DATA},
            });
        }
    }, [formData, setFormData]);

    const onChange = useCallback(({ target }) => {
        setFormData({
            ...formData,
            data: {
                ...formData.data,
                [target.name]: target.value,
            }
        });
    },[formData, setFormData]);

    const onSave = async () => {
        await createMediaplan(formData.data);
        await history.push('/radio/broadcast');
        await setFormData({
            entityName: RADIO_ENTITY.MEDIAPLAN,
            data: {...DEFAULT_FORM_DATA},
        });
    };

    const isLoaded = formData.entityName === RADIO_ENTITY.MEDIAPLAN;
    const data = formData && formData.data;

    const disabledButton = !data?.dateFrom || !data?.dateTo || !data?.description || !data?.title || data?.objects?.length === 0 || (data?.dateFrom >  data?.dateTo);

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            {isLoaded && (<>
                <div className={styles.form}>
                    <div className={styles.desktopLeftCol}>
                        <Input
                            onChange={onChange}
                            name="title"
                            value={data.title}
                            label="Название медиаплана"
                            inputClassName={styles.nameInput}
                        />
                        <Input
                            onChange={onChange}
                            name="description"
                            value={data.description}
                            type="textarea"
                            label="Описание"
                        />
                    </div>
                    <div className={styles.desktopRightCol}>
                        <Input
                            name="dateFrom"
                            type="date"
                            value={data.dateFrom}
                            onChange={onChange}
                            label="Дата начала"
                        />
                        <Input
                            name="dateTo"
                            type="date"
                            value={data.dateTo}
                            onChange={onChange}
                            label="Дата окончания"
                        />
                        <Select
                            label="Интенсивность медиаплана"
                            options={selectOptions}
                            value={data.intensity}
                            onChange={onChange}
                            name="intensity"
                        />
                        <div className={styles.objectsInfoContainer}>
                            <div className={styles.objectsInfo}>
                                <div className={styles.title}>
                                    Список объектов медиаплана
                                </div>
                                <div className={styles.value}>
                                    Выбрано: <span className={styles.count}>{data?.objects?.length} объектов</span>
                                </div>
                            </div>
                            <Button href="/radio/mediaplan/objects/add">
                                Выбрать
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={styles.buttonsContainer}>
                    <Button href="/radio/broadcast" className={styles.hideDesktop}>
                        Отменить
                    </Button>
                    {!disabledButton && (
                        <Button onClick={onSave} className={styles.createButton}>
                            Создать
                        </Button>
                    )}
                </div>
            </>)}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaplanCreateScene);
