import React from 'react';
import styles from './ObjectPriorityAdsScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import ListContainer from "../../../../components/ListContainer";
import {useRadioPriorityAds} from "../../../../hooks/radio/useRadioPriorityAds";
import {Link} from "react-router-dom";
import PriorityAdCard from "../../../../components/Cards/PriorityAdCard";

const generateBreadcrumbsRoutes = (id) => [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Объекты',
    href: '/radio/objects'
}, {
    name: 'Страница объекта',
    href: `/radio/objects/${id}`
}, {
    name: 'Приоритетные объявления',
    href: `/radio/objects/${id}/priority`
}];

const ObjectPriorityAdScene = ({match}) => {
    const {id} = match.params;

    const {data, isLoaded, onDelete, onEdit} = useRadioPriorityAds(id);

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={generateBreadcrumbsRoutes(id)}/>
            <div className={styles.buttonContainer}>
                <Link to={`/radio/objects/${id}/priority/create`} className={styles.createButton}>
                    Создать
                </Link>
            </div>
            {isLoaded && (
                <ListContainer noLoader className={styles.listContainer}>
                    {data?.list?.map(item => (
                        <PriorityAdCard
                            data={item}
                            key={item.id}
                            onDelete={onDelete}
                            onEdit={onEdit}
                        />
                    ))}
                </ListContainer>
            )}
        </div>
    );
};

export default ObjectPriorityAdScene;
