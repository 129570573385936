import {api} from "../App";
import {STUDIO_ENTITY} from "../constants/studioEntities";
import {STUDIO_URL} from "./config";

const CATEGORIES_URL = `${STUDIO_URL}/track-categories`;
const STATUSES_URL = `${STUDIO_URL}/track-statuses`;
const TYPES_URL = `${STUDIO_URL}/track-types`;
const VOICES_URL = `${STUDIO_URL}/track-voices`;
const ARCHIVE_URL = `${STUDIO_URL}/archived-tracks`;
const TRACK_ORDER_URL = `${STUDIO_URL}/track-orders`;
const TRACKS = `${STUDIO_URL}/tracks`

export const fetchStudioCategories = async () => {
    return api.get(CATEGORIES_URL);
};

export const fetchStudioStatuses = async () => {
    return api.get(STATUSES_URL);
};

export const fetchStudioTypes = async () => {
    return api.get(TYPES_URL);
};

export const fetchStudioVoices = async () => {
    return api.get(VOICES_URL);
};

export const fetchStudioData = (entityName, params) => {
    return api.get(`${STUDIO_URL}/${entityName}`, {params})
};

export const fetchStudioRecord = (entityName, recordId) => {
    if (entityName === STUDIO_ENTITY.ARCHIVE) {
        return api.get(`${STUDIO_URL}/${STUDIO_ENTITY.RECORDS}/${recordId}`);
    }
    return api.get(`${STUDIO_URL}/${entityName}/${recordId}`)
};

export const fetchStudioAudio = (trackId, entityName) => {
    return api.get(`${STUDIO_URL}/${entityName}/${trackId}/play`, {
        responseType: 'arraybuffer' })
};

export const postArchiveTracks = (trackIds) => {
    return api.post(ARCHIVE_URL, trackIds);
};

export const deleteArchiveTracks = (trackIds) => {
    return api.delete(ARCHIVE_URL, {data: trackIds});
};

export const postTrackOrder = () => {
    return api.post(TRACK_ORDER_URL);
}

export const confirmOrder = (id) => {
    return api.post(`${TRACK_ORDER_URL}/${id}/confirm`);
}

export const rejectOrder = (id) => {
    return api.post(`${TRACK_ORDER_URL}/${id}/reject`);
}

export const fetchTracks = (params) => {
    return api.get(TRACKS, { params });
}

export const excludeFromAir = (id) => {
    return api.post(`${TRACKS}/${id}/exclude-from-air`);
};
