import React, {useEffect, useState} from 'react';
import styles from './MediaplanRecordsScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import ItemListTitle from "../../../../components/ItemListTitle";
import mediaplanIcon from '../../../../img/icons/mediaplan-icon-grey.svg';
import Search from "../../../../components/Search";
import RecordCard from "../../../../components/Cards/RecordCard";
import {Link, useHistory, useParams} from "react-router-dom";
import CardCheckbox from "../../../../components/Cards/CardCheckbox";
import ListContainer from "../../../../components/ListContainer";
import {useRadioMediaplan} from "../../../../hooks/radio/useRadioMediaplan";
import {editRadioMediaplan} from "../../../../redux/modules/radio/radio-actions";
import {useDispatch, useSelector} from "react-redux";
import {loadTracks} from "../../../../redux/modules/studio/studio-actions";
import {tracksSelector} from "../../../../redux/modules/studio/studio-selectors";
import CheckboxWithLabel from "../../../../components/common/Checkbox/CheckboxWithLabel";
import Pagination from "../../../../components/Pagination";

const breadcrumbsRoutes = (id) => [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Эфир',
    href: '/radio/broadcast'
}, {
    name: 'Страница медиаплана',
    href: `/radio/mediaplans/${id}`
}, {
    name: 'Ролики медиаплана',
    href: `/radio/mediaplans/${id}/records`
}];

const MediaplanRecordsScene = () => {
    const [selectedTracks, setSelectedTracks] = useState([]);
    const {id} = useParams();
    const history = useHistory();
    const [entityData, isLoaded] = useRadioMediaplan(id);
    const dispatch = useDispatch();
    const mediaplanInfo = entityData?.data;
    const tracksData = useSelector(tracksSelector);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        setSelectedTracks(mediaplanInfo?.tracks ?? [])
    }, [mediaplanInfo])

    useEffect(() => {
        const effect = async () => {
            await dispatch(loadTracks({status: 'ACTIVE', _page: currentPage}));
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

        effect();
    }, [dispatch, currentPage])

    const onToggle = (track) => selectedTracks.includes(track) ?
        setSelectedTracks(selectedTracks.filter(item => item.id !== track.id)) :
        setSelectedTracks([...selectedTracks, track]);

    const toggleAll = (e) => {
        e.preventDefault();
        return selectedTracks.length !== tracksData?.items.length ?
            setSelectedTracks(tracksData?.items) : setSelectedTracks([]);
    };

    const onSubmit = async () => {
        await dispatch(editRadioMediaplan({
            description: mediaplanInfo?.description,
            title: mediaplanInfo?.title,
            objects: mediaplanInfo?.objects?.map(object => object.id) || [],
            tracks: selectedTracks?.map(track => ({
                id: track.id,
                periods: [{
                    dateFrom: mediaplanInfo.dateFrom,
                    dateTo: mediaplanInfo.dateTo,
                }],
            }))
        }, mediaplanInfo?.id))
        history.push(`/radio/mediaplans/${id}`);
    }

    const onDelete = (id) => {
        const updateTracks = selectedTracks?.filter(item => item.id !== id);
        setSelectedTracks(updateTracks);
    }

    return (
        <>
            <div className={styles.container}>
                <Breadcrumbs routes={breadcrumbsRoutes(id)}/>
                <ItemListTitle icon={mediaplanIcon}
                               title={mediaplanInfo?.title}>
                    ID {mediaplanInfo?.id}
                </ItemListTitle>
                <CheckboxWithLabel onChange={toggleAll}
                                   checked={!!selectedTracks.length && selectedTracks.length === tracksData?.items.length}
                                   className={styles.toggleAll}
                                   labelChecked='Снять'
                                   labelUnchecked='Выбрать'/>
                <ListContainer className={styles.listContainer}>
                    {tracksData?.items.map((item) =>
                        <RecordCard key={item.id} record={item}
                                    showMenu={mediaplanInfo?.tracks.includes(item)}
                                    showStatus
                                    onClick={(e) => {
                                        onToggle(item);
                                        e.preventDefault();
                                    }}
                                    onDelete={onDelete}
                                    checkbox={<CardCheckbox
                                        onChange={(e) => onToggle(item)}
                                        checked={!!selectedTracks.find(track => track.id === item.id)}/>}
                        />
                    )}
                </ListContainer>
                <Pagination
                    count={tracksData?.totalElements}
                    entityName="Роликов"
                    totalPages={tracksData?.totalPages}
                    resultsPerPage={tracksData?.pageSize}
                    currentPage={tracksData?.pageNumber}
                    setPage={setCurrentPage}
                    items={tracksData?.items}/>
            </div>
            <div className={styles.endBlock}>
                <div className={styles.buttons}>
                    <Link to={`/radio/mediaplans/${id}`}>
                        <button className={styles.back}>Назад</button>
                    </Link>
                    <button
                        className={styles.ready}
                        onClick={onSubmit}
                    >
                        Готово
                    </button>
                </div>
            </div>
        </>
    );
};

export default MediaplanRecordsScene;
