import React from 'react';
import styles from './Filter.module.scss';
import DatePicker from "../../common/DatePicker";
import Checkbox from "../../common/Checkbox";
import classNames from 'class-names';
import moment from "moment";

const Filter = ({
                    filters,
                    setFilters,
                    resetFilters,
                    selectedFilters
                }) => {
    const [currentFilters, setCurrentFilters] = React.useState(selectedFilters);

    const onDateChange = (range) => {
        const { selection } = range;
        if (selection && selection.startDate && selection.endDate) {
            const filterDates = {
                dateCreatedFrom: moment(selection.startDate).format('YYYY-MM-DD'),
                dateCreatedTo: moment(selection.endDate).format('YYYY-MM-DD')
            };

            setCurrentFilters({
                ...currentFilters,
                ...filterDates,
            })
        }
    }

    const createChangeHandler = filterType => (event) => {
        if (currentFilters[filterType] === event.target.value) {
            setCurrentFilters({
                ...currentFilters,
                [filterType]: undefined,
            })
        } else {
            setCurrentFilters({
                ...currentFilters,
                [filterType]: String(event.target.value)
            })
        }
    };

    const onReset = () => {
        setFilters({});
        resetFilters();
    }

    const renderFilter = (filter, entityName) => (<Checkbox
        onChange={createChangeHandler(entityName)}
        checked={String(filter.id) === currentFilters[entityName]}
        value={filter.id}
        key={filter.id}
    >
        {filter.name}
    </Checkbox>)

    const onSubmit = () => {
        setFilters(currentFilters);
    }

    return (
        <div className={styles.container}>
            <div className={classNames(styles.flexContainer, !filters?.voices && styles.containerOrderType)}>
                {!!filters?.voices &&
                    <DatePicker onChange={onDateChange}
                                value={currentFilters.dateCreatedFrom && currentFilters.dateCreatedTo
                                    ? [new Date(currentFilters.dateCreatedFrom), new Date(currentFilters.dateCreatedTo)] : undefined}
                                className={styles.datePicker} />
                }
                <form className={styles.form}>
                    <div className={styles.section}>
                        <div className={styles.sectionTitle}>
                            Статус
                        </div>
                        <div className={styles.sectionContent}>
                            {filters.statuses.map(filter => renderFilter(filter, 'status'))}
                        </div>
                    </div>
                    {!!filters?.voices &&
                        <>
                            <div className={styles.section}>
                                <div className={styles.sectionTitle}>
                                    Дикторы
                                </div>
                                <div className={styles.sectionContent}>
                                    {filters.voices.map(filter => renderFilter(filter, 'voice'))}
                                </div>
                            </div>
                            <div className={styles.section}>
                                <div className={styles.sectionTitle}>
                                    Тип
                                </div>
                                <div className={styles.sectionContent}>
                                    {filters.types.map(filter => renderFilter(filter, 'type'))}
                                </div>
                            </div>
                            <div className={styles.section}>
                                <div className={styles.sectionTitle}>
                                    Категория
                                </div>
                                <div className={classNames(styles.sectionContent, styles.category)}>
                                    {filters.categories.map(filter => renderFilter(filter, 'categoryId'))}
                                </div>
                            </div>
                        </>
                    }
                </form>
            </div>
            <div className={styles.buttonsContainer}>
                <div className={styles.button} onClick={onSubmit}>
                    Применить
                </div>
                <div className={styles.button} onClick={onReset}>
                    Сбросить
                </div>
            </div>
        </div>
    );
};

export default Filter;
