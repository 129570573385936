import React, {useEffect, useMemo, useState} from 'react';
import styles from './BroadcastHoursScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import objectIcon from "../../../../img/icons/object-dark-grey-icon.svg";
import RecordStatus from "../../../../components/Cards/RecordCard/RecordStatus";
import {RECORD_ONLINE_STATUS, RECORD_STATUS} from "../../../../constants/recordTypes";
import ItemListTitle from "../../../../components/ItemListTitle";
import TimePicker from "../../../../components/TimePicker";
import Button from "../../../../components/common/Button";
import Select from "../../../../components/common/Select";
import {useRequest} from "ahooks";
import {getBroadcastReference, getHoursOnAir, putHoursOnAir} from "../../../../api/radio-api";
import moment from "moment";
import {showModal} from "../../../../redux/actions/ui/modal/modal";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import Spinner from "../../../../components/Spinner";

const breadcrumbsRoutes = [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Объекты',
    href: '/radio/objects'
}, {
    name: 'Страница объекта',
    href: '/radio/object'
}, {
    name: 'Часы вещания',
    href: '/radio/object/broadcast-hours'
}];

const BROADCAST_OPTIONS = [
    {
        name: 'объекта',
        value: 'open'
    },
    {
        name: 'фона',
        value: 'background'
    },
    {
        name: 'эфира',
        value: 'air'
    },
]

const BroadcastHoursScene = () => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const [selectedOption, setSelectedOption] = useState(BROADCAST_OPTIONS[0]);
    const [data, setData] = useState();

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const onSelectChange = (e) => {
        const optionValue = e.target.value;

        setSelectedOption(BROADCAST_OPTIONS.find(option => option.value === optionValue));
    }

    const {run, loading} = useRequest(() => getHoursOnAir(id, selectedOption.value), {
        refreshDeps: [id, selectedOption],
        onSuccess: (response) => {
            setData(response.data);
            const [hoursStart, minutesStart] = response.data.timeFrom.split(':');
            const [hoursEnd, minutesEnd] = response.data.timeTo.split(':');

            setStartDate(moment().set({
                hours: Number(hoursStart),
                minutes: Number(minutesStart)
            }).toDate());

            setEndDate(moment().set({
                hours: Number(hoursEnd),
                minutes: Number(minutesEnd)
            }).toDate());
        },
        onError: (error => {
            dispatch(showModal({
                title: 'Ошибка',
                text: error.response.data.errorMessage,
                cancelText: 'Закрыть',
                submitText: null,
            }))
        })
    });

    if (!data) {
        return <div className={styles.container}>
            <Spinner/>
        </div>
    }

    const onStartChange = ({hours, minutes}) => {
        setStartDate(moment(startDate).set({hours, minutes}).toDate());
    }

    const onEndChange = ({hours, minutes}) => {
        setEndDate(moment(endDate).set({hours, minutes}).toDate());
    }

    const onSubmit = async () => {
        await putHoursOnAir(id, selectedOption.value, {
            timeFrom: moment(startDate).format('HH:mm'),
            timeTo: moment(endDate).format('HH:mm')
        });

        run();
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes} className={styles.hideDesktop}/>
            <ItemListTitle icon={objectIcon}
                           className={styles.pageTitle}
                           title={""}>
                ID 51464 <RecordStatus onlineStatus={RECORD_ONLINE_STATUS.ONLINE}
                                       status={RECORD_STATUS.READY}/>
            </ItemListTitle>
            <div className={styles.pageContainer}>
                <div className={styles.currentValueContainer}>
                    <div className={styles.contentLabel}>
                        Часы вещания <Select options={BROADCAST_OPTIONS}
                                             value={selectedOption.value}
                                             className={styles.selectWrapper}
                                             onChange={onSelectChange}/>
                    </div>
                    <div className={styles.currentValue}>
                        {data.timeFrom} - {data.timeTo}
                    </div>
                </div>
                {!loading &&
                    <div className={styles.pickerContainer}>
                        <div className={styles.contentLabel}>
                            Часы вещания с:
                        </div>
                        <TimePicker date={startDate} onChange={onStartChange} className={styles.timePicker}/>
                    </div>}
                {!loading &&
                <div className={styles.pickerContainer}>
                    <div className={styles.contentLabel}>
                        Часы вещания по:
                    </div>
                    <TimePicker date={endDate} onChange={onEndChange} className={styles.timePicker}/>
                </div>}
                <div className={styles.buttonContainer}>
                    <Button onClick={onSubmit} className={styles.submitButton}>
                        Применить
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default BroadcastHoursScene;
