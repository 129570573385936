import React, {useCallback, useEffect, useState} from 'react';
import styles from './EditUserDataScene.module.scss';
import { useSetCurrentSection } from "../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../constants/pageTypes";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Input from "../../../components/common/Input";
import ProfileButton from "../../../components/Profile/ProfileButton";
import {useDispatch, useSelector} from "react-redux";
import {
    loadProfile, sendProfileChangeEmail,
    sendProfileChangePassword,
    sendProfileChangePhone, sendProfileChangeUser
} from "../../../redux/modules/profile/profile-actions";
import {profileSelectorData} from "../../../redux/modules/profile/profile-selectors";
import Spinner from "../../../components/Spinner";
// import {uiSetCurrentSection} from "../../../redux/actions/ui/currentPage/currentSection";
import {useHistory} from "react-router-dom";

const breadcrumbsRoutes = [{
    name: 'Личный кабинет',
    href: '#'
}, {
    name: 'Изменение перс. информации',
    href: '/profile'
}];

const EDIT_INFO_STAGE = {
    FORM: 'FORM',
    RESULT: 'RESULT',
};

const EditUserDataScene = () => {
    const [stage, setStage] = useState(EDIT_INFO_STAGE.FORM);
    const [form, setForm] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();
    const profile = useSelector(profileSelectorData);
    useSetCurrentSection(PAGE_SUBSECTIONS.PROFILE);

    const createChangeListener = (name) => (e) => {
        setForm({ ...form, [name]: e.target.value });
    };

    const onSubmitUser = () => {
        if (form.hasOwnProperty('newPhone')) {
            dispatch(sendProfileChangePhone({ newPhone: form?.newPhone?.slice(2, 12) }));
        }

        if (form.hasOwnProperty('newEmail')) {
            dispatch(sendProfileChangeEmail({ newEmail: form?.newEmail }));
        }

        if (form.hasOwnProperty('fio')) {
            dispatch(sendProfileChangeUser({ fio: form?.fio }));
        }

        setForm({});
        history.goBack();
    };

    const onSubmitPassword = () => {
        dispatch(sendProfileChangePassword(form));
        setForm({});
        setStage(EDIT_INFO_STAGE.RESULT);
    };

    if (!profile) {
        return (
            <div className={styles.spinnerContainer}>
                <Spinner />
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <div className={styles.pageContainer}>
                {stage === EDIT_INFO_STAGE.FORM &&
                    <div className={styles.formContainer}>
                        <div className={styles.formElementLabel}>
                            Номер телефона
                        </div>
                        <Input name="phone"
                               onChange={createChangeListener('newPhone')}
                               defaultValue={`+7${profile.phone}`}
                               className={styles.formInputContainer}
                               inputClassName={styles.formInput}/>
                        <div className={styles.formElementLabel}>
                            Email
                        </div>
                        <Input name="email"
                               onChange={createChangeListener('newEmail')}
                               defaultValue={profile.email}
                               className={styles.formInputContainer}
                               inputClassName={styles.formInput}/>
                        <div className={styles.formElementLabel}>
                            Контактное лицо
                        </div>
                        <Input name="fio"
                               onChange={createChangeListener('fio')}
                               defaultValue={profile.fio}
                               className={styles.formInputContainer}
                               inputClassName={styles.formInput}/>
                        <ProfileButton primary onClick={onSubmitUser}>
                            Сохранить
                        </ProfileButton>
                        <div className={styles.passwordForm}>
                            <div className={styles.formElementLabel}>
                                Cтарый пароль
                            </div>
                            <Input name="password"
                                   type="password"
                                   className={styles.formInputContainer}
                                   inputClassName={styles.formInput}
                                   onChange={createChangeListener('oldPassword')}
                            />
                            <div className={styles.formElementLabel}>
                                Новый пароль
                            </div>
                            <Input name="password"
                                   type="password"
                                   className={styles.formInputContainer}
                                   inputClassName={styles.formInput}
                                   onChange={createChangeListener('newPassword')}
                            />
                            <ProfileButton onClick={onSubmitPassword} primary>
                                Сохранить
                            </ProfileButton>
                        </div>
                    </div>
                }
                {false &&
                    <div className={styles.hintContainer}>
                        <div className={styles.hintTitle}>
                            Подсказка
                        </div>
                        <div className={styles.hint}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ut mauris tellus. Ut vitae
                            tristique nisi, a aliquet nisi. Duis magna enim, porta non felis quis, blandit maximus quam.
                        </div>
                    </div>
                }
                {stage === EDIT_INFO_STAGE.RESULT &&
                    <div className={styles.resultContainer}>
                        <div className={styles.resultTitle}>
                            Пароль успешно изменён
                        </div>
                        <div className={styles.resultDesc}>
                            Теперь ваш аккаунт в безопасности.
                        </div>
                        <div className={styles.resultButtons}>
                            <ProfileButton href={'/profile'} fullWidth>
                                Назад
                            </ProfileButton>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default EditUserDataScene;
