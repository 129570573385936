import React, {useState} from 'react';
import styles from './PriorityCreateScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import {useSetCurrentSection} from "../../../../hooks/useSetCurrentSection";
import {PAGE_SUBSECTIONS, PAGE_TYPE} from "../../../../constants/pageTypes";
import Checkbox from "../../../../components/common/Checkbox/NewCheckbox";
import Input from "../../../../components/common/Input";
import Button from "../../../../components/common/Button";
import Search from "../../../../components/StudioSearch";
import classNames from 'class-names';
import Pagination from "../../../../components/Pagination";
import CardCheckbox from "../../../../components/Cards/CardCheckbox";
import ListContainer from "../../../../components/ListContainer";
import {createStudioDataSelector} from "../../../../redux/modules/studio/studio-selectors";
import {STUDIO_ENTITY} from "../../../../constants/studioEntities";
import {addTracksToArchive, loadStudioData, orderNewTrack} from "../../../../redux/modules/studio/studio-actions";
import {showModal} from "../../../../redux/actions/ui/modal/modal";
import {connect} from "react-redux";
import Spinner from "../../../../components/Spinner";
import StudioRecordCard from "../../../../components/Cards/StudioRecordCard";
import recordIcon from "../../../../img/icons/record-icon.svg";
import {useSelectableList} from "../../../../hooks/useSelectableList";
import {useStudioMetadata} from "../../../../hooks/useStudioMetadata";
import {createPriorityAd} from "../../../../api/radio-api";
import {useHistory} from "react-router-dom";

const generateBreadcrumbsRoutes = (id) => [
    {
        name: 'Корпоративное радио',
        href: '#'
    },
    {
        name: 'Объекты',
        href: '/radio/objects'
    },
    {
        name: 'Страница объекта',
        href: `/radio/objects/${id}`
    },
    {
        name: 'Приоритетные объявления',
        href: `/radio/objects/${id}/priority`
    },
    {
        name: 'Создать',
        href: `/radio/objects/${id}/priority/create`
    }
];

const mapStateToProps = (state) => ({
    data: createStudioDataSelector(STUDIO_ENTITY.RECORDS)(state),
});

const mapDispatchToProps = dispatch => ({
    showModal: (data) => dispatch(showModal(data)),
    loadData: (page) => dispatch(loadStudioData(STUDIO_ENTITY.RECORDS, page)),
});

const PriorityCreateScene = ({data, loadData, match, showModal}) => {
    useSetCurrentSection(PAGE_SUBSECTIONS.PRIORITY);
    const { id } = match.params;

    const [metadataLoaded] = useStudioMetadata();

    const [onToggle, selectAll, selectedIds, unselectAll, setSelectedIds] = useSelectableList(undefined, false);
    const [title, setTitle] = useState('');
    const [isFavorite, setFavorite] = useState(false);

    const history = useHistory();

    const onChange = (e) => {
        setTitle(e.target.value)
    }

    React.useEffect(() => {
        loadData();
    }, [loadData]);

    const setPage = (page) => {
        loadData(page);
    };

    const onCreate = async () => {
        if (!title || !title.length || !selectedIds.length) {
            showModal({
                title: "Ошибка",
                text: "Выберите ролик и введите название объявления",
                submitText: "Закрыть",
                cancelText: null,
            });

            return;
        }

        const data = {
            favorite: isFavorite,
            title,
            trackId: selectedIds[0],
        }

        try {
            const result = await createPriorityAd(id, data);
            history.push(`/radio/objects/${id}/priority`);
        } catch (error) {
            if (error.response && error.response.data) {
                const responseData = error.response.data;
                if (responseData.errorMessage) {
                    showModal({
                        title: 'Ошибка',
                        text: responseData.errorMessage,
                        cancelText: 'Закрыть',
                        submitText: null,
                    })
                }
            }
        }
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={generateBreadcrumbsRoutes(id)}/>
            <div className={styles.pageContainer}>
                <div className={styles.form}>
                    <Input name={'name'}
                           onChange={onChange}
                           value={title}
                           label={'Название объявления'}
                           inputClassName={styles.input}/>
                    <Checkbox
                        checked={isFavorite}
                        onChange={() => setFavorite(!isFavorite)}>
                        Избранное
                    </Checkbox>
                </div>
                <div className={classNames(styles.buttonsContainer, styles.hideMobile)}>
                    <Button href={`/radio/objects/${id}/priority`}>
                        Отменить
                    </Button>
                    <Button onClick={onCreate} className={styles.createButton}>
                        Создать
                    </Button>
                </div>
            </div>

            <div className={styles.searchContainer}>
                {metadataLoaded && <Search
                    entity={STUDIO_ENTITY.RECORDS}
                />}
            </div>
            <div className={classNames(styles.buttonsContainer, styles.hideDesktop)}>
                <Button href={`/radio/objects/${id}/priority`}>
                    Отменить
                </Button>
                <Button onClick={onCreate} className={styles.createButton}>
                    Создать
                </Button>
            </div>
            {data.isLoading ? (
                    <div className={styles.spinnerContainer}>
                        <Spinner/>
                    </div>
                )
                : (
                    <ListContainer noLoader className={styles.listContainer}>
                        {data.list.map((record, index) => (
                            <StudioRecordCard
                                record={record}
                                checkbox={<CardCheckbox
                                    onChange={() => onToggle(record.id)}
                                    checked={selectedIds.includes(record.id)}/>}
                                showStatus={true}
                                entity={STUDIO_ENTITY.RECORDS}
                                icon={recordIcon}
                                key={index}
                            />))}
                    </ListContainer>
                )}
            {!data.isLoading && <Pagination
                entityName={'Роликов'}
                selectable
                totalPages={data.totalPages}
                count={data.totalRecords}
                resultsPerPage={data.pageSize}
                currentPage={data.pageNumber}
                setPage={setPage}
                setSelected={setSelectedIds}
                selectedIds={selectedIds}
                items={data.list}
            />}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PriorityCreateScene);
